import { UserAvatar1, UserAvatar2, UserAvatar3, UserAvatar4, UserAvatar5, UserAvatar6, FeatureImg1, FeatureImg2, FeatureImg3, FeatureImg4 } from '../../components/Common/Image'

export const feedbacks = [
  {
    avatar: UserAvatar1,
    name: 'Viezh Robert',
    address: 'Warsaw, Poland',
    feedback: '“We\'ve been very pleased with our experience working with Pixelconn. Their team was incredibly helpful in helping us find the perfect developer for our project."'
  },
  {
    avatar: UserAvatar2,
    name: 'Anna German',
    address: 'Kyiv, Ukraine',
    feedback: '“With the pressure on and millions watching the Cleveland Cavaliers during the NBA Playoffs, Pixelconn delivered the talent and expertise needed to launch a new product.”'
  },
  {
    avatar: UserAvatar3,
    name: 'Alexander Sofino',
    address: 'London, Great Britain',
    feedback: '“With Pixelconn, we are able to find talented developers in a matter of weeks instead of months. The quality of talent they supply is extremely high."'
  },
  {
    avatar: UserAvatar4,
    name: 'Dan Benyamin',
    address: 'Warsaw, Poland',
    feedback: '“Pixelconn is not only a marketplace but a place to do business. We meet new clients and more often than not establish long-term business relationships, all through Pixelconn.'
  },
  {
    avatar: UserAvatar5,
    name: 'Derek Lundsten',
    address: 'Belgrade, Serbia',
    feedback: '“Pixelconn has helped deliver on a major project along with some minor ones. They\'re able to adapt well to client feedback and treat the relationship as a two-way street”'
  },
  {
    avatar: UserAvatar6,
    name: 'Alexander Sofino',
    address: 'London, Great Britain',
    feedback: '“This is very unusual for my business that currently requires a virtual private network that has high security.”..'
  }
]

export const featureItems = [
  {
    img: FeatureImg1,
    no: '01',
    title: 'featured.items.1.title',
    description: 'featured.items.1.description'
  },
  {
    img: FeatureImg2,
    no: '02',
    title: 'featured.items.2.title',
    description: 'featured.items.2.description'
  },
  {
    img: FeatureImg3,
    no: '03',
    title: 'featured.items.3.title',
    description: 'featured.items.3.description'
  },
  {
    img: FeatureImg4,
    no: '04',
    title: 'featured.items.4.title',
    description: 'featured.items.4.description'
  }
]
