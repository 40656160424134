import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import * as QueryString from 'querystring'
import { Menu } from 'antd'

import * as S from './styles'
import { DarkLogo } from '../Common/Icon'

const { SubMenu } = Menu

const SideMenu = (props) => {

  const { t } = useTranslation('menu')
  const history = useHistory()
  const location = useLocation()
  const [ openKeys, setOpenKeys ] = useState([''])
  const [ selectedKeys, setSelectedKeys ] = useState<string[]>([])
  const [ category, setCategory ] = useState<string | undefined>(undefined)
  const [ industry, setIndustry ] = useState<string | undefined>(undefined)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setCategory(params.get('category') || undefined)
    setIndustry(params.get('industry') || undefined)
    if (params.get('category')) {
      const portfolioSelectedKeys: string[] = []
      portfolioSelectedKeys.push(`portfolio.category.${params.get('category')}`)
      portfolioSelectedKeys.push(`portfolio.industry.${params.get('industry')}`)

      setSelectedKeys(portfolioSelectedKeys)
    }
  }, [location])

  const { open, closeMenu } = props

  const onOpenChange = keys => {
    const rootSubmenuKeys = ['development', 'portfolio']
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  const onMenuItemSelect = (e) => {
    const selectedKey = e?.keyPath && e.keyPath.length ? e.keyPath[0] : null

    if (selectedKey.indexOf('.')  === -1) {
      history.push(`/${selectedKey}`)
    }
    if (selectedKey && selectedKey.indexOf('development') === 0) {
      history.push(`/development/${selectedKey.split('.')[1]}`)
    }
    if (selectedKey && selectedKey.indexOf('portfolio') === 0) {
      const query = {
        category,
        industry
      }
      if (selectedKey.indexOf('category') > 0) {
        setCategory(selectedKey.split('.')[2])
        query.category = selectedKey.split('.')[2]
      } else {
        setIndustry(selectedKey.split('.')[2])
        query.industry = selectedKey.split('.')[2]
      }
      history.push({
        pathname: '/portfolio',
        search: '?' + QueryString.stringify(query)
      })
    }
    // @ts-ignore
    setSelectedKeys([selectedKey])
  }

  return (
    <S.Drawer anchor={'left'} open={open} onClose={closeMenu}>
      <S.MobileLogo to='/' onClick={closeMenu}><DarkLogo width={200}/></S.MobileLogo>
      <Menu
        mode="inline"
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        onOpenChange={onOpenChange}
        onSelect={onMenuItemSelect}
        onClick={closeMenu}
        onKeyDown={closeMenu}

        theme="dark"
      >
        <SubMenu key="development" title={t('whatWeDo.title')}>
          <Menu.Item key="development.web">{t('whatWeDo.web')}</Menu.Item>
          <Menu.Item key="development.mobile">{t('whatWeDo.mobile')}</Menu.Item>
          <Menu.Item key="development.blockchain">{t('whatWeDo.blockchain')}</Menu.Item>
          <Menu.Item key="development.game">{t('whatWeDo.game')}</Menu.Item>
          <Menu.Item key="development.desktop">{t('whatWeDo.desktop')}</Menu.Item>
        </SubMenu>
        <SubMenu key='portfolio' title={t('portfolio.title')}>
          <Menu.ItemGroup key="category" title={t('portfolio.category.title')}>
            <Menu.Item key='portfolio.category.web'>{t('portfolio.category.web')}</Menu.Item>
            <Menu.Item key='portfolio.category.mobile'>{t('portfolio.category.mobile')}</Menu.Item>
            <Menu.Item key='portfolio.category.blockchain'>{t('portfolio.category.blockchain')}</Menu.Item>
            <Menu.Item key='portfolio.category.desktop'>{t('portfolio.category.desktop')}</Menu.Item>
            <Menu.Item key='portfolio.category.game'>{t('portfolio.category.game')}</Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup key="industry" title={t('portfolio.industry.title')}>
            <Menu.Item key='portfolio.industry.enterprise'>{t('portfolio.industry.enterprise')}</Menu.Item>
            <Menu.Item key='portfolio.industry.finance'>{t('portfolio.industry.finance')}</Menu.Item>
            <Menu.Item key='portfolio.industry.social'>{t('portfolio.industry.social')}</Menu.Item>
            <Menu.Item key='portfolio.industry.healthcare'>{t('portfolio.industry.healthcare')}</Menu.Item>
            <Menu.Item key='portfolio.industry.dapp'>{t('portfolio.industry.dapp')}</Menu.Item>
            <Menu.Item key='portfolio.industry.crm'>{t('portfolio.industry.crm')}</Menu.Item>
            <Menu.Item key='portfolio.industry.ecommerce'>{t('portfolio.industry.ecommerce')}</Menu.Item>
            <Menu.Item key='portfolio.industry.blog'>{t('portfolio.industry.blog')}</Menu.Item>
            <Menu.Item key='portfolio.industry.fitness'>{t('portfolio.industry.fitness')}</Menu.Item>
            <Menu.Item key='portfolio.industry.others'>{t('portfolio.industry.others')}</Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        <Menu.Item key='expertise'>{t('ourExpertise.title')}</Menu.Item>
        <Menu.Item key='company'>{t('company.title')}</Menu.Item>
        <Menu.Item key='blog'>{t('blog.title')}</Menu.Item>
        <Menu.Item key='contact-us'>{t('contacts.title')}</Menu.Item>
      </Menu>
    </S.Drawer>
  )
}

export default SideMenu
