import styled from 'styled-components'

const MainSection = styled.section`
  .content {
    padding: 120px 135px 120px;

    ${props => props.theme.breakpoints.down('lg')} {
      padding: 120px 0 120px;
    }

    ${props => props.theme.breakpoints.down('sm')} {
      padding: 120px 20px 120px;
    }

    .sub-header {
      font: 20px/30px CircularStd-Book;
      color: ${props => props.theme.colors.white};
      text-align: center;
      text-transform: capitalize;
      margin: 0;
    }

    .loading-container {
      height: 500px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .no-data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 200px;

      h5 {
        font: 20px/30px CircularStd-Book;
        color: ${props => props.theme.colors.grey700};
        margin-top: 15px;
      }
    }

    .portfolio-card-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 30px;
      grid-auto-rows: 1fr;
      margin-top: 60px;

      ${props => props.theme.breakpoints.down('sm')} {
        grid-template-columns: 1fr 1fr;
        grid-gap: 15px;
      }

      ${props => props.theme.breakpoints.down(650)} {
        grid-template-columns: 1fr;

        > div {
          margin: 0 auto;
        }
      }
    }
  }
`

const PortfolioCard = styled.div`
  border-radius: 10px;
  position: relative;
  transition: ease-in-out 0.1s;
  cursor: pointer;
  min-width: 200px;
  min-height: 200px;
  overflow: hidden;

  .img-container {
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .card-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
    background-color: ${props => props.theme.colors.blue}BB;

    .summary {
      height: 90px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 20px;

      .title {
        font: 36px/48px Roboto-Bold;
        color: ${props => props.theme.colors.white};
        margin: 0;
        text-align: center;
      }
    }
  }

  .card-hovered {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    border-radius: 10px;
    z-index: 3;
    border: 1px solid ${props => props.theme.colors.blue300};
    background: linear-gradient(125deg, ${props => props.theme.colors.blue600}, ${props => props.theme.colors.blue400});
    padding: 30px 40px;
    transition: opacity 0.3s;
    overflow: hidden;

    .title {
      font: 22px/29px Roboto-Bold;
      color: ${props => props.theme.colors.yellow};
      margin: 30px 0 5px;
    }

    .description {
      font: 18px/27px Roboto-Regular;
      color: ${props => props.theme.colors.white};
      margin: 0;
      overflow: hidden;
      white-space: pre-wrap;
      display: -webkit-box;
      -webkit-line-clamp: 9;
      -webkit-box-orient: vertical;
      display: -moz-box;
      -moz-line-clamp: 9;
      -moz-box-orient: vertical;
    }

    ${props => props.theme.breakpoints.down('md')} {
      .description {
        -webkit-line-clamp: 6;
        -moz-line-clamp: 6;
      }
    }

    ${props => props.theme.breakpoints.down('xs')} {
      .title, .description {
        text-align: center;
      }
    }

    ${props => props.theme.breakpoints.down(450)} {
      padding: 20px;

      .description {
        -webkit-line-clamp: 5;
        -moz-line-clamp: 5;
      }
    }
  }

  &:hover {
    margin: -35px;

    .img-container, .card-content {
      opacity: 0;
    }

    .card-hovered {
      opacity: 1;
    }

    ${props => props.theme.breakpoints.down('sm')} {
      margin: -20px;
    }
  }

  ${props => props.theme.breakpoints.down(650)} {
    max-height: 350px;
    max-width: 350px;
    width: 100%;
  }
`

const EllipseBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1 !important;

  ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .big-ellipse {
      width: 986.69px;
      height: 986.69px;
      background: rgba(255, 255, 255, 0.05);
      filter: blur(24px);
      border-radius: 50%;
    }

    .small-ellipse {
      width: 496.61px;
      height: 496.61px;
      background: rgba(255, 255, 255, 0.03);
      filter: blur(24px);
      border-radius: 50%;
    }

    .ellipse-1 {
      position: absolute;
      top: 450px;
      left: -147px;
    }

    .ellipse-2 {
      position: absolute;
      top: 1231px;
      right: -67px;
    }

    .ellipse-3 {
      position: absolute;
      left: -90px;
      top: 1217px;
    }
  }
`

export { MainSection, EllipseBackground, PortfolioCard }
