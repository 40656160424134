import React from 'react'

import {
  AndroidIcon,
  AngularJsIcon,
  CIcon,
  CodeIgniterIcon,
  CPlusIcon,
  CSharpIcon,
  CssIcon,
  ExpressJsIcon,
  GoIcon,
  HtmlIcon,
  IOSIcon,
  JavascriptIcon,
  LaravelIcon,
  NodeJsIcon,
  PhpIcon,
  PythonIcon,
  ReactJsIcon,
  RubyIcon,
  SymfonyIcon,
  TypescriptIcon,
  UnityIcon,
  VueJsIcon,
  WordpressIcon
} from '../../components/Common/Icon'
import {
  ExpertNew1Img, ExpertNew2Img, ExpertNew3Img, ExpertNew4Img, ExpertNew5Img, ExpertNew6Img, ExpertNew7Img, ExpertNew8Img, ExpertNew9Img} from '../../components/Common/Image'
import {IExpert, ISkill} from '../../shared/interfaces'
import {DevelopmentType, Skill} from '../../shared/enums'

export const SkillsData: ISkill[] = [
  {
    name: "Go",
    icon: <GoIcon />,
    category: [DevelopmentType.WEB, DevelopmentType.BLOCKCHAIN]
  },
  {
    name: "Python",
    icon: <PythonIcon/>,
    category: [DevelopmentType.WEB, DevelopmentType.MOBILE, DevelopmentType.BLOCKCHAIN]
  },
  {
    name: "Ruby",
    icon: <RubyIcon/>,
    category: [DevelopmentType.WEB, DevelopmentType.BLOCKCHAIN, DevelopmentType.DESKTOP]
  },
  {
    name: "PHP",
    icon: <PhpIcon/>,
    category: [DevelopmentType.WEB]
  },
  {
    name: "Laravel",
    icon: <LaravelIcon/>,
    category: [DevelopmentType.WEB]
  },
  {
    name: "Symfony",
    icon: <SymfonyIcon/>,
    category: [DevelopmentType.WEB, DevelopmentType.BLOCKCHAIN]
  },
  {
    name: "CodeIgniter",
    icon: <CodeIgniterIcon/>,
    category: [DevelopmentType.WEB]
  },
  {
    name: "WordPress",
    icon: <WordpressIcon/>,
    category: [DevelopmentType.WEB]
  },
  {
    name: "Node.js",
    icon: <NodeJsIcon/>,
    category: [DevelopmentType.WEB, DevelopmentType.BLOCKCHAIN, DevelopmentType.MOBILE, DevelopmentType.GAME, DevelopmentType.DESKTOP]
  },
  {
    name: "Express.js",
    icon: <ExpressJsIcon/>,
    category: [DevelopmentType.WEB]
  },
  {
    name: "React.js",
    icon: <ReactJsIcon/>,
    category: [DevelopmentType.WEB, DevelopmentType.MOBILE, DevelopmentType.GAME, DevelopmentType.BLOCKCHAIN, DevelopmentType.DESKTOP]
  },
  {
    name: "Vue.js",
    icon: <VueJsIcon/>,
    category: [DevelopmentType.WEB, DevelopmentType.MOBILE, DevelopmentType.GAME, DevelopmentType.BLOCKCHAIN]
  },
  {
    name: "Angular.js",
    icon: <AngularJsIcon/>,
    category: [DevelopmentType.WEB, DevelopmentType.MOBILE, DevelopmentType.GAME, DevelopmentType.BLOCKCHAIN]
  },
  {
    name: "Typescript",
    icon: <TypescriptIcon/>,
    category: [DevelopmentType.WEB, DevelopmentType.BLOCKCHAIN]
  },
  {
    name: "Javascript",
    icon: <JavascriptIcon/>,
    category: [DevelopmentType.WEB, DevelopmentType.MOBILE]
  },
  {
    name: "HTML",
    icon: <HtmlIcon/>,
    category: [DevelopmentType.WEB, DevelopmentType.MOBILE, DevelopmentType.GAME]
  },
  {
    name: "CSS",
    icon: <CssIcon/>,
    category: [DevelopmentType.WEB]
  },
  {
    name: "Android",
    icon: <AndroidIcon/>,
    category: [DevelopmentType.MOBILE, DevelopmentType.GAME, DevelopmentType.BLOCKCHAIN]
  },
  {
    name: "iOS",
    icon: <IOSIcon/>,
    category: [DevelopmentType.WEB, DevelopmentType.MOBILE, DevelopmentType.GAME, DevelopmentType.BLOCKCHAIN, DevelopmentType.DESKTOP]
  },
  {
    name: "Unity",
    icon: <UnityIcon/>,
    category: [DevelopmentType.MOBILE, DevelopmentType.GAME, DevelopmentType.DESKTOP]
  },
  {
    name: "C",
    icon: <CIcon/>,
    category: [DevelopmentType.MOBILE, DevelopmentType.GAME, DevelopmentType.BLOCKCHAIN, DevelopmentType.DESKTOP]
  },
  {
    name: "C++",
    icon: <CPlusIcon/>,
    category: [DevelopmentType.MOBILE, DevelopmentType.GAME, DevelopmentType.BLOCKCHAIN, DevelopmentType.DESKTOP]
  },
  {
    name: "C#",
    icon: <CSharpIcon/>,
    category: [DevelopmentType.MOBILE, DevelopmentType.GAME, DevelopmentType.BLOCKCHAIN, DevelopmentType.DESKTOP]
  }
]

export const ExpertsData: IExpert[] = [
  {
    name: 'Vicky Russ',
    img: ExpertNew1Img,
    job: 'experts.1.job',
    description: 'experts.1.description',
    category: [DevelopmentType.WEB, DevelopmentType.DESKTOP, DevelopmentType.MOBILE, DevelopmentType.GAME, DevelopmentType.BLOCKCHAIN],
    skills: [Skill.REACT, Skill.VUE, Skill.ANDROID],
    projects: ['Alchemymachines', 'Altoros']
  },
  {
    name: 'Rot Mitt',
    img: ExpertNew2Img,
    job: 'experts.2.job',
    description: 'experts.2.description',
    category: [DevelopmentType.MOBILE, DevelopmentType.WEB, DevelopmentType.DESKTOP],
    skills: [Skill.REACT, Skill.VUE, Skill.ANDROID],
    projects: ['Alchemymachines', 'Altoros']
  },
  {
    name: 'Laura Hanz',
    img: ExpertNew3Img,
    job: 'experts.3.job',
    description: 'experts.3.description',
    category: [DevelopmentType.GAME, DevelopmentType.BLOCKCHAIN, DevelopmentType.WEB],
    skills: [Skill.REACT, Skill.VUE, Skill.ANDROID],
    projects: ['Alchemymachines', 'Altoros']
  },
  {
    name: 'Aman Nais',
    img: ExpertNew4Img,
    job: 'experts.4.job',
    description: 'experts.4.description',
    category: [DevelopmentType.WEB, DevelopmentType.BLOCKCHAIN],
    skills: [Skill.REACT, Skill.VUE, Skill.ANDROID],
    projects: ['Alchemymachines', 'Altoros']
  },
  {
    name: 'Francisca Lemon',
    img: ExpertNew5Img,
    job: 'experts.5.job',
    description: 'experts.5.description',
    category: [DevelopmentType.WEB, DevelopmentType.MOBILE, DevelopmentType.DESKTOP],
    skills: [Skill.REACT, Skill.VUE, Skill.ANDROID],
    projects: ['Alchemymachines', 'Altoros']
  },
  {
    name: 'Reine Tais',
    img: ExpertNew6Img,
    job: 'experts.6.job',
    description: 'experts.6.description',
    category: [DevelopmentType.WEB, DevelopmentType.GAME, DevelopmentType.BLOCKCHAIN, DevelopmentType.DESKTOP, DevelopmentType.MOBILE],
    skills: [Skill.REACT, Skill.VUE, Skill.ANDROID],
    projects: ['Alchemymachines', 'Altoros']
  },
  {
    name: 'Joao Barbosa',
    img: ExpertNew7Img,
    job: 'experts.7.job',
    description: 'experts.7.description',
    category: [DevelopmentType.WEB, DevelopmentType.GAME, DevelopmentType.BLOCKCHAIN, DevelopmentType.DESKTOP, DevelopmentType.MOBILE],
    skills: [Skill.REACT, Skill.VUE, Skill.ANDROID],
    projects: ['Alchemymachines', 'Altoros']
  },
  {
    name: 'Nikita Alexandar',
    img: ExpertNew8Img,
    job: 'experts.8.job',
    description: 'experts.8.description',
    category: [DevelopmentType.WEB, DevelopmentType.GAME, DevelopmentType.BLOCKCHAIN, DevelopmentType.DESKTOP, DevelopmentType.MOBILE],
    skills: [Skill.REACT, Skill.VUE, Skill.ANDROID],
    projects: ['Alchemymachines', 'Altoros']
  },
  {
    name: 'Dragan Boris',
    img: ExpertNew9Img,
    job: 'experts.9.job',
    description: 'experts.9.description',
    category: [DevelopmentType.WEB, DevelopmentType.GAME, DevelopmentType.BLOCKCHAIN, DevelopmentType.DESKTOP, DevelopmentType.MOBILE],
    skills: [Skill.REACT, Skill.VUE, Skill.ANDROID],
    projects: ['Alchemymachines', 'Altoros']
  },
]
