import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import * as S from './styles'
import { Logo } from '../Common/Icon'

const Footer = () => {
  const { t } = useTranslation('menu')

  return (
    <S.FooterSection>
      <div className='container'>
        <S.Content>
          <div style={{ display: 'flex' }}>
            <div className='block logo'>
              <Link to='/'><Logo width={200} /></Link>
            </div>
            <S.MenuList>
              <div className='menu-group'>
                <S.MenuTitle to='/development'>{t('whatWeDo.title')}</S.MenuTitle>
                <S.MenuItem to='/development/web'>{t('whatWeDo.web')}</S.MenuItem>
                <S.MenuItem to='/development/mobile'>{t('whatWeDo.mobile')}</S.MenuItem>
                <S.MenuItem to='/development/blockchain'>{t('whatWeDo.blockchain')}</S.MenuItem>
                <S.MenuItem to='/development/game'>{t('whatWeDo.game')}</S.MenuItem>
                <S.MenuItem to='/development/desktop'>{t('whatWeDo.desktop')}</S.MenuItem>
              </div>
              <div className='menu-group'>
                <S.MenuTitle to='/portfolio'>{t('portfolio.title')}</S.MenuTitle>
                <S.MenuItem to='/portfolio?category=web&industry='>{t('portfolio.webCloud')}</S.MenuItem>
                <S.MenuItem to='/portfolio?category=&industry=healthcare'>{t('portfolio.healthcare')}</S.MenuItem>
                <S.MenuItem to='/portfolio?category=&industry=dapp'>{t('portfolio.dapp')}</S.MenuItem>
                <S.MenuItem to='/portfolio?category=mobile&industry='>{t('portfolio.android')}</S.MenuItem>
                <S.MenuItem to='/portfolio?category=mobile&industry=' transform='initial'>{t('portfolio.ios')}</S.MenuItem>
              </div>
              <div className='menu-group'>
                <S.MenuTitle to='/expertise'>{t('ourExpertise.title')}</S.MenuTitle>
                <S.MenuItem to='/expertise/web'>{t('ourExpertise.web')}</S.MenuItem>
                <S.MenuItem to='/expertise/mobile'>{t('ourExpertise.mobile')}</S.MenuItem>
                <S.MenuItem to='/expertise/blockchain'>{t('ourExpertise.blockchain')}</S.MenuItem>
                <S.MenuItem to='/expertise/game'>{t('ourExpertise.game')}</S.MenuItem>
                <S.MenuItem to='/expertise/desktop'>{t('ourExpertise.desktop')}</S.MenuItem>
              </div>
              <div className='menu-group'>
                <S.MenuTitle to='/company'>{t('company.title')}</S.MenuTitle>
                <S.MenuItem to='/company/values'>{t('company.values')}</S.MenuItem>
                <S.MenuItem to='/company/management'>{t('company.management')}</S.MenuItem>
                <S.MenuItem to='/company/principles'>{t('company.principles')}</S.MenuItem>
              </div>
              <div className='menu-group'>
                <S.MenuTitle to='/contact-us'>{t('contacts.title')}</S.MenuTitle>
                <S.MenuItem to='/contact-us'>{t('contacts.city')}</S.MenuItem>
                <S.MenuItem to='/contact-us' transform='initial'>{t('contacts.company')}</S.MenuItem>
                <S.MenuItem to='/contact-us' transform='initial'>{t('contacts.street')}</S.MenuItem>
                <br/>
                <S.MenuItem to='#' transform='initial'>{t('contacts.license')}</S.MenuItem>
                <S.MenuItem to='#' transform='initial'>{t('contacts.url')}</S.MenuItem>
              </div>
            </S.MenuList>
          </div>
          <S.FooterText>{t('sigmaSoftware')}</S.FooterText>
        </S.Content>
      </div>
    </S.FooterSection>
  )
}

export default Footer;
