import React from 'react'
import { useTranslation } from 'react-i18next'

import * as S from './styles'
import { SupportData } from "../../pages/WhatWeDo/data";

const SupportSection = () => {
  const { t } = useTranslation('whatWeDo')

  return (
    <S.Container>
      <div className='container'>
        <div className='content'>
          <h3>{t('support.title')}</h3>
          <div className='stage-card-list'>
            {
              SupportData.map((data) => (
                <S.StageCard key={data.no}>
                  <h4 className='title'>{t(data.title)}</h4>
                  <p className='description'>{t(data.description)}</p>
                  <div className='divider' />
                  <h5 className='sub-title'>{t(data.subTitle)}</h5>
                  <ul>
                    {
                      data.details.map((detailItem, index) => (
                        <li key={index}>{t(detailItem)}</li>
                      ))
                    }
                  </ul>
                  <h3 className='number'>{data.no}</h3>
                </S.StageCard>
              ))
            }
          </div>
        </div>
      </div>
    </S.Container>
  )
}

export default SupportSection
