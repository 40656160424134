import React, { useState, useRef, useEffect } from 'react'
import { RightOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Fade, Hidden } from "@material-ui/core";
import { useTranslation } from 'react-i18next'

import * as S from './styles'

interface CarouselItem {
  img: string,
  no: string,
  title: string,
  description: string
}

interface FeatureCarouselProps {
  data: CarouselItem[],
  onChangeActiveItemIndex?: (index: number) => void,
  startIndex?: number,
  translationNs: string
}

const DISTANCE = 428
const MOVE_SPEED = 15

const FeatureCarousel = (props: FeatureCarouselProps) => {

  const { data: carouselData, startIndex, onChangeActiveItemIndex, translationNs } = props
  const { t } = useTranslation(translationNs)
  const [ data, setData ] = useState(carouselData)
  const [ movedDistance, setMovedDistance ] = useState(0)
  const [ isMoving, setIsMoving ] = useState(false)
  const [ activeItemIndex, setActiveItemIndex ] = useState(startIndex || 0)
  const [ shiftItems, setShiftItems ] = useState(startIndex && startIndex > 0)

  const ref = useRef(null)
  let timer

  if (shiftItems) {
    const newItemList = [...data]
    const shiftedItems = newItemList.splice(0, startIndex)
    if (shiftedItems.length) {
      newItemList.push(...shiftedItems)
    }

    setData([...newItemList])
    setShiftItems(false)
  }

  const onMove = (speed) => {
    return new Promise((resolve) => {
      if (ref && ref.current) {

        if (isMoving) return
        let movedDistance = 0
        setIsMoving(true)

        const activeFeatureItem = document.getElementById('active-feature-item')

        timer = setInterval(() => {
          movedDistance += speed;

          if (movedDistance > DISTANCE) {
            movedDistance = DISTANCE
            clearInterval(timer)

            if (activeFeatureItem) {
              activeFeatureItem.style.minWidth = `${S.ITEM_WIDTH}px`
              activeFeatureItem.style.height = `${S.ITEM_HEIGHT}px`
            }

            resolve();
          } else {
            // grow active item
            if (activeFeatureItem) {
              activeFeatureItem.style.minWidth = `${Math.floor(movedDistance / DISTANCE * (S.ACTIVE_ITEM_WIDTH - S.ITEM_WIDTH)) + S.ITEM_WIDTH}px`
              activeFeatureItem.style.height = `${Math.floor(movedDistance / DISTANCE * (S.ACTIVE_ITEM_HEIGHT - S.ITEM_HEIGHT)) + S.ITEM_HEIGHT}px`
            }
          }
          setMovedDistance(movedDistance)
          // @ts-ignore
          ref.current.style.marginLeft = `-${movedDistance}px`;
        }, 10);
      }
    });
  }

  const goTo = async (count) => {
    for (let i = 0; i < count; i++) {
      await onMove(count * MOVE_SPEED)
      setActiveItemIndex((activeItemIndex + i + 1) % data.length)
    }
    if (onChangeActiveItemIndex) {
      onChangeActiveItemIndex((activeItemIndex + count - 1) % data.length)
    }
  }

  useEffect(() => {
    if (movedDistance >= DISTANCE) {
      const newItemList = [...data]
      const firstItem = newItemList.shift()
      if (firstItem) {
        newItemList.push(firstItem)
      }

      setData([...newItemList])
      setMovedDistance(0)
      setIsMoving(false)
      // @ts-ignore
      ref.current.style.marginLeft = `0px`
    }
  }, [data, movedDistance])

  return (
    <>
      <Hidden smDown>
        <S.Container ref={ref}>
          {
            data.map((item, index) => (
              <S.Item active={index === 0} key={item.title + index} style={{ opacity: `${index === 0 && isMoving ? ( DISTANCE - movedDistance ) / DISTANCE : 1}` }} id={index === 1 ? 'active-feature-item' : 'feature-item'}>
                <div className='bg-image'>
                  <img src={item.img} alt={item.no} />
                </div>
                <Fade timeout={300} in={ index === 0 }>
                  <div className='footer'>
                    <div className='card'>
                      <div className='header'>
                        <h4>{item.no}</h4>
                        <span />
                        <h4>{t(`${item.title}`)}</h4>
                      </div>
                      <p className='description'>{t(`${item.description}`)}</p>
                    </div>
                    {/*<S.ArrowButton><ArrowRightOutlined /></S.ArrowButton>*/}
                  </div>
                </Fade>
              </S.Item>
            ))
          }
          <S.NextButton onClick={() => goTo(1)}><RightOutlined /></S.NextButton>
          <div className='dots'>
            {
              Array(data.length).fill(0).map((_, index) => (
                <div
                  key={index}
                  className={`dot ${index === activeItemIndex ? 'active' : ''}`}
                  onClick={() => goTo(index > activeItemIndex ? index - activeItemIndex : index + data.length - activeItemIndex)}
                >
                  <span />
                </div>
              ))
            }
          </div>
        </S.Container>
      </Hidden>
      <Hidden mdUp>
        <S.MobileContainer>
          {
            data.map((item, index) => (
              <S.Item key={index}>
                <div className='bg-image'>
                  <img src={item.img} alt={item.no} />
                </div>
                <div className='footer'>
                  <div className='card'>
                    <div className='header'>
                      <h4>{item.no}</h4>
                      <span />
                      <h4>{t(`${item.title}`)}</h4>
                    </div>
                    <p className='description'>{t(`${item.description}`)}</p>
                  </div>
                  <S.ArrowButton><ArrowRightOutlined /></S.ArrowButton>
                </div>
              </S.Item>
            ))
          }
        </S.MobileContainer>
      </Hidden>
    </>
  )
}

export default FeatureCarousel
