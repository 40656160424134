import axios from 'axios'
import * as types from '../actionTypes'
import * as BlogApi from '../../apis/blog.api'

import { ENABLE_MOCK_DATA } from '../../config'

export const setCategories = (value: string) => {
  return {
    type: types.SET_BLOG_CATEGORIES,
    payload: value
  };
}

export const setTopArticles = (value: string) => {
  return {
    type: types.SET_TOP_ARTICLES,
    payload: value
  };
}

export const setBlogs = (value) => {
  return {
    type: types.SET_BLOGS,
    payload: value
  };
}

export const setBlogDetail = (value) => {
  return {
    type: types.SET_BLOG_DETAIL,
    payload: value
  }
}

export const getBlogs = (category: string, subCategory: string, page: number, size = 5) => async (dispatch) => {
  try {
    let response
    if (ENABLE_MOCK_DATA) {
      response = await axios.get('mock/blogs.json')
    } else {
      response = await BlogApi.getPosts(page, size)
    }
    if (response.data) {
      dispatch(setBlogs({...response.data.data}))
    }
  } catch (error) {
    throw (error)
  }
}

export const getCategories = () => async (dispatch) => {
  try {
    const response = await axios.get('mock/categories.json')
    if (response.data.categories) {
      dispatch(setCategories(response.data.categories))
    }
  } catch (error) {
    throw (error)
  }
}

export const getTopArticles = (size: number) => async (dispatch) => {
  try {
    let response
    if (ENABLE_MOCK_DATA) {
      response = await axios.get('mock/topArticles.json')
    } else {
      response = await BlogApi.getTopPosts(size)
    }
    if (response.data.data) {
      dispatch(setTopArticles(response.data.data))
    }
  } catch (error) {
    throw (error)
  }
}

export const getBlogDetail = (id: number) => async (dispatch) => {
  try {
    let response
    if (ENABLE_MOCK_DATA) {
      response = await axios.get('/mock/blogDetail.json')
    } else {
      response = await BlogApi.getPost(id)
    }
    if (response.data) {
      dispatch(setBlogDetail(response.data.data))
    }
  } catch (error) {
    throw (error)
  }
}
