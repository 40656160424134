/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { matchPath, RouteComponentProps, withRouter } from 'react-router-dom'

export interface ComponentWithCurrentRoute {
  /** Link for react-router */
  to: string
  /** If set to true `active` prop will be calculated based on exact URL match */
  exact?: boolean
  /** Automatically calculates based on URL match by react-router */
  active?: boolean
}

export function withCurrentRoute<Props extends ComponentWithCurrentRoute>(
  WrappedComponent: React.ComponentType<Props>
) {
  return withRouter((props: Omit<Props & RouteComponentProps, 'active'>) => {
    const path = props.to as string

    const { exact, location, history, match, staticContext, ...componentProps } = props

    // Regex taken from: https://github.com/pillarjs/path-to-regexp/blob/master/index.js#L202
    const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1')

    const pathToMatch = location.pathname

    const matching = escapedPath ? matchPath(pathToMatch, { path: escapedPath, exact }) : undefined

    return <WrappedComponent {...((componentProps as unknown) as Props)} active={Boolean(matching)} />
  })
}
