import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import mainEn from './locales/en/main.json'
import menuEn from './locales/en/menu.json'
import whatWeDoEn from './locales/en/whatWeDo.json'
import expertiseEn from './locales/en/expertise.json'
import portfolioEn from './locales/en/portfolio.json'
import companyEn from './locales/en/company.json'
import contactsEn from './locales/en/contacts.json'
import blogEn from './locales/en/blog.json'

const resources = {
  en: {
    main: mainEn,
    menu: menuEn,
    whatWeDo: whatWeDoEn,
    expertise: expertiseEn,
    portfolio: portfolioEn,
    company: companyEn,
    contacts: contactsEn,
    blog: blogEn
  }
}

const language = localStorage.getItem('language') || 'en'

i18n.use(initReactI18next).init({
  resources,
  lng: language,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
})

export const changeLanguage = (code: string): void => {
  i18n.changeLanguage(code)
  localStorage.setItem('language', code)
}

export default i18n
