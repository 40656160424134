import styled from 'styled-components'
import { Button } from 'antd'

import { HeroBgImg, ExodusBgImg } from '../../components/Common/Image'

const MainSection = styled.section`
  background-image: url(${HeroBgImg});
  background-position: 50% 67%;
  background-size: cover;
  padding: 234px 0 120px;
  margin-top: -130px;

  .content {
    padding: 0 71px 0 57px;

    .button-group {
      margin-top: 40px;
      padding: 0 100px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      .button {
        font: 18px/32px CircularStd-Bold;
        color: white;

        .label {
          margin-left: 10px;
        }
      }
    }
  }

  ${props => props.theme.breakpoints.down("lg")} {
    .content {
      padding: 0;
    }

    .button-group {
      padding: 0 !important;
    }
  }

  ${props => props.theme.breakpoints.down("sm")} {
    .button-group {
      padding: 0 !important;
      grid-template-columns: 1fr !important;
      grid-row-gap: 20px;
    }
  }
`

const TopPanel = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 110px;

  .text-wrapper {
    max-width: 750px;
    text-align: center;

    h1 {
      margin: 15px 0 37px;
    }

    h2 {
      font: 30px/40px CircularStd-Medium;
      color: ${props => props.theme.colors.yellow};
      margin: 0;
    }

    p {
      font: 24px/30px Roboto-Regular;
      color: ${props => props.theme.colors.white};
      margin: 0;
    }
  }

  .social-links {
    position: absolute;
    left: 100px;
    bottom: 0;
    writing-mode: vertical-lr;

    .item {
      cursor: pointer;
      margin-top: 20px;
      font: 18px/24px CircularStd-Medium;
      color: white;
      transition: 0.2s;

      svg {
        fill: white;
        transition: 0.2s;
      }

      &:hover {
        color: ${props => props.theme.colors.yellow};

        svg {
          fill: ${props => props.theme.colors.yellow};
        }
      }
    }
  }

  .typewriter {
    margin: 15px 0 37px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .typewriter-text {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      box-sizing: border-box;
      font: 48px/55px Roboto-Bold;
      color: white;
      text-transform: uppercase;
      text-align: center;

      ${props => props.theme.breakpoints.down("sm")} {
        font: 40px/48px Roboto-Bold;
        white-space: break-spaces;
      }

      &.text-1 {
        // animation: typing 5s steps(30, end), blink, .75s step-end infinite;
        // -webkit-animation-fill-mode: forwards;
        // animation-fill-mode: forwards;
      }

      &.text-2 {
        // opacity: 0;
        // animation: type2 5s steps(30, end), blink, .75s step-end infinite alternate;
        // animation-delay: 5s;
        // -webkit-animation-fill-mode: forwards;
        // animation-fill-mode: forwards;
      }
    }

    @keyframes typing {
      from {
        width: 0;
      }
      to {
        width: 100%
      }
    }

    @keyframes type2 {
      0% {
        width: 0;
      }
      1% {
        opacity: 1;
      }
      99.9% {
        width: 100%
      }
      100% {
        opacity: 1;
        border: none;
        width: 100%

      }
    }


    @keyframes blink {
      from, to {
        border-color: transparent;
      }

      50% {
        border-color: orange;
      }
    }
  }

  ${props => props.theme.breakpoints.down("md")} {
    .social-links {
      writing-mode: initial;
      bottom: -60px;
      right: 0;
      left: unset;

      .item {
        margin-left: 20px;
        margin-top: 0;
      }
    }
  }
`

const ExodusLanding = styled.div`
  width: 100%;
  border-radius: 10px;
  background: ${props => props.theme.colors.light200};
  padding: 50px 80px;

  .item {
    padding-right: 50px;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 72px;
      height: 72px;
      border-radius: 6px;
      background: linear-gradient(90deg, ${props => props.theme.colors.blue200}, ${props => props.theme.colors.blue100});
      margin-bottom: 26px;
    }

    .indicator {
      width: 72px;
      height: 4px;
      background-color: ${props => props.theme.colors.yellow};
      margin: 10px 0 18px;
    }

    h3 {
      font: 16px/20px CircularStd-Bold;
      margin: 0;
      color: ${props => props.theme.colors.blue500};
      text-transform: uppercase;
    }

    p {
      font: 16px/28px Roboto-Regular;
      margin: 0;
      color: ${props => props.theme.colors.grey};
    }
  }

  ${props => props.theme.breakpoints.down(992)} {
    .item {
      text-align: center;
      padding-right: 0;

      .icon {
        margin: 0 auto 26px;
      }

      .indicator {
        margin: 10px auto 18px;
      }
    }
  }

  ${props => props.theme.breakpoints.down(500)} {
    padding: 50px 40px;

    .item {
      padding-right: 10px;
    }
  }
`

const IconButton = styled(Button)`
  width: 60px !important;
  height: 60px !important;
  border: none !important;
  border-radius: 50% !important;
  background-color: ${props => props.theme.colors.light};
`

const WhatWeDoSection = styled.section`
  .content {
    padding: 120px 75px 120px;
  }

  ${props => props.theme.breakpoints.down("lg")} {
    .content {
      padding: 120px 0 120px;
    }
  }

  ${props => props.theme.breakpoints.down("md")} {
    .button-group {
      padding: 0 !important;
      grid-template-columns: 1fr !important;
      grid-row-gap: 20px;
    }
  }
`

const BenefitsSection = styled.section`
  background-image: url(${ExodusBgImg});
  background-position: 50% 72%;
  background-size: cover;

  .content {
    padding: 120px 135px;
    text-align: center;

    h1 {
      margin-bottom: 40px;
    }

    .benefits-item-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-row-gap: 70px;
      grid-column-gap: 200px;

      > div {
        max-width: 500px;
        margin: 0 auto;
      }
    }
  }

  ${props => props.theme.breakpoints.down("lg")} {
    .content {
      padding: 120px 0;

      .benefits-item-list {
        grid-column-gap: 100px;
      }
    }
  }

  ${props => props.theme.breakpoints.down("md")} {
    .benefits-item-list {
      grid-column-gap: 50px !important;
    }
  }

  ${props => props.theme.breakpoints.down("sm")} {
    .benefits-item-list {
      grid-template-columns: 1fr 1fr !important;
      grid-column-gap: 50px !important;
    }
  }

  ${props => props.theme.breakpoints.down("xs")} {
    .benefits-item-list {
      grid-template-columns: 1fr !important;
      grid-column-gap: 50px !important;
    }
  }
`

const BenefitsItem = styled.div`
  display: flex;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 83px;
    min-width: 83px;
    height: 83px;
    border-radius: 10px;
    border: dashed 1px ${props => props.theme.colors.light100};
    background: ${props => props.theme.colors.white};
    margin-top: 10px;
  }

  .right {
    text-align: left;
    padding-left: 30px;

    h3 {
      font: 21px/34px CircularStd-Bold;
      color: ${props => props.theme.colors.yellow};
      margin-bottom: 7px;
      text-transform: uppercase;
    }

    p {
      font: 16px/25px CircularStd-Book;
      color: ${props => props.theme.colors.white};
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: center;

    .right {
      padding-left: 0;
      margin-top: 30px;
      text-align: center;
    }
  }
`

const TestimonialsSection = styled.section`
  background-image: url(${ExodusBgImg});
  background-position: 50% 72%;
  background-size: cover;

  .content {
    padding: 120px 115px;
    text-align: center;

    h1 {
      margin-bottom: 40px;
    }
  }

  ${props => props.theme.breakpoints.down("lg")} {
    .content {
      padding: 120px 30px;
    }
  }
`

const FeedbackItem = styled.div`
  padding: 0 20px;

  .item-container {
    padding: 30px;
    background: ${props => props.theme.colors.white};
    border-radius: 10px;
    min-height: 250px;
    transition: all ease 0.5s;
    border: 3px solid transparent;
    cursor: pointer;

    .header {
      display: flex;
      margin-bottom: 17px;

      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 20px;
      }

      .profile {
        .name {
          font: 18px/30px Roboto-Medium;
          color: ${props => props.theme.colors.blue700};
          margin: 0;
          text-align: left;
        }

        .address {
          font: 14px/17px Roboto-Regular;
          color: ${props => props.theme.colors.blue50};
          margin: 0;
          text-align: left;
        }
      }
    }

    .feedback {
      font: 14px/25px Roboto-Regular;
      text-align: left;
      margin-bottom: 0;
    }

    &:hover {
      background: linear-gradient(125deg, ${props => props.theme.colors.blue600}, ${props => props.theme.colors.blue400});
      border-color: ${props => props.theme.colors.blue300};

      .name, .feedback {
        transition: all ease 0.2s;
        color: ${props => props.theme.colors.white} !important;
      }
    }
  }

  ${props => props.theme.breakpoints.down("lg")} {
    .item-container {
      padding: 20px;
      min-height: 250px;
    }
  }
`

const FeaturedSection = styled.section`
  .content {
    padding: 120px 0 120px 135px;
    display: flex;
    overflow: hidden;

    .left {
      width: 370px;
      margin-right: 40px;
      z-index: 10;

      h1 {
        text-align: left;
      }

      .description {
        color: white;
        font: 16px/28px Roboto-Regular;
        margin: 74px 0 66px;
      }
    }
  }

  ${props => props.theme.breakpoints.down("lg")} {
    .content {
      padding-left: 0;
    }
  }

  ${props => props.theme.breakpoints.down("sm")} {
    .content {
      flex-direction: column;

      .left {
        width: 100%;
        margin: 0 0 30px 0;
        text-align: center;

        h1 {
          text-align: center;
        }

        .description {
          max-width: 450px;
          margin: 30px auto;
        }
      }
    }
  }
`

export {
  MainSection,
  TopPanel,
  ExodusLanding,
  IconButton,
  WhatWeDoSection,
  BenefitsSection,
  BenefitsItem,
  TestimonialsSection,
  FeedbackItem,
  FeaturedSection
}
