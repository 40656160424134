import styled from 'styled-components'

interface DevelopmentItemProps {
  isFocus?: boolean,
  active?: boolean,
  color?: string
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  ${props => props.theme.breakpoints.down("lg")} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 50px;

    > div {
      margin: 0 auto;
    }
  }

  ${props => props.theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 50px;
  }

  ${props => props.theme.breakpoints.down("xs")} {
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
  }
`

const CarouselContainer = styled.div`
  padding: 0 20px;
`

const DevelopmentItem = styled.div<DevelopmentItemProps>`
  margin: 0 auto;
  width: 240px;

  .img-container {
    position: relative;
    width: 100%;
    height: 240px;

    .bg-image {
      position: absolute;
      z-index: 1;
      cursor: pointer;
      transition: 0.5s;
    }

    .character-image {
      position: absolute;
      z-index: 2;
      pointer-events: none;
      transition: 0.5s;
    }

    .shadow-image-1 {
      z-index: 3;
      position: absolute;
      transform: rotate(34deg);
      pointer-events: none;
      transition: 0.5s;
    }

    .shadow-image-2 {
      z-index: 4;
      position: absolute;
      transform: rotate(18deg);
      pointer-events: none;
      transition: 0.5s;
    }
  }

  .label {
    padding-left: 60px;
    cursor: pointer;

    h5 {
      font: 21px/25px Roboto-Bold;
      color: ${props => props.active ? props.color : props.theme.colors.white};
      text-transform: uppercase;
      transition: 0.3s;
    }

    ${props => props.theme.breakpoints.down('sm')} {
      padding-left: 30px;

      h5 {
        width: 180px;
        text-align: center;
      }
    }
  }

  &.web {
    .img-container {
      .bg-image {
        left: 55px;
        top: 56px;
      }

      .character-image {
        left: 91px;
        top: 100px;
      }

      .shadow-image-1 {
        left: 28px;
        bottom: 0;
      }

      .shadow-image-2 {
        right: 15px;
        top: 35px;
      }

      &:hover {
        .bg-image, .character-image {
          transform: scale(1.1);
          transition: 0.3s;
        }

        .shadow-image-1 {
          transform: scale(0.9);
        }

        .shadow-image-2 {
          top: 0;
        }
      }
    }
  }

  &.mobile {
    .img-container {
      .bg-image {
        left: 40px;
        top: 63px;
      }

      .phone1 {
        left: 98px;
        top: 96px;
      }

      .phone2 {
        top: 94px;
        left: 71px;
      }

      .phone3 {
        left: 112px;
        top: 99px;
      }

      .shadow-image-1 {
        right: 8px;
        top: 54px;
      }

      .shadow-image-2 {
        left: 34px;
        bottom: 14px;
      }

      &:hover {
        .bg-image, .phone1 {
          transform: scale(1.1);
        }

        .phone2 {
          transform: rotate(-15deg) scale(1.1);
        }

        .phone3 {
          transform: rotate(15deg) scale(1.1);
        }

        .shadow-image-1 {
          transform: rotate(50deg);
        }

        .shadow-image-2 {
          transform: rotate(50deg);
        }
      }
    }
  }

  &.blockchain {
    .img-container {
      .bg-image {
        left: 46px;
        top: 44px;
      }

      .character-image {
        left: 59px;
        top: 94px;
      }

      .shadow-image-1 {
        right: 5px;
        top: 23px;
      }

      .shadow-image-2 {
        left: 17px;
        bottom: 27px;
      }

      &:hover {
        .bg-image {
          transform: scale(1.1);
        }

        .character-image {
          transform: rotate(-10deg);
        }

        .shadow-image-1 {
          top: 0;
        }

        .shadow-image-2 {
          bottom: 0;
        }
      }
    }
  }

  &.game {
    .img-container {
      .bg-image {
        top: 48px;
        left: 32px;
      }

      .character-image {
        top: 101px;
        left: 56px;
      }

      .shadow-image-1 {
        right: 20px;
        bottom: 16px;
      }

      .shadow-image-2 {
        left: 17px;
        top: 42px;
      }

      &:hover {
        .bg-image, .character-image {
          transform: scale(1.1);
        }

        .shadow-image-1 {
          bottom: 40px;
          right: -20px;
        }

        .shadow-image-2 {
          top: 70px;
          left: -20px;
        }
      }
    }
  }

  &.desktop {
    .img-container {
      .bg-image {
        left: 37px;
        bottom: 25px;
      }

      .character-image {
        left: 83px;
        top: 98px;
      }

      .shadow-image-1 {
        bottom: 10px;
        right: 28px;
      }

      .shadow-image-2 {
        top: 30px;
        left: 33px;
      }

      &:hover {
        .bg-image, .character-image {
          transform: scale(1.1);
        }

        .shadow-image-1 {
          bottom: 20px;
          right: 0;
        }

        .shadow-image-2 {
          top: 40px;
          left: 0;
        }
      }
    }
  }
`

export { Container, DevelopmentItem, CarouselContainer }
