import styled from 'styled-components'

import { ExodusBgImg, GameBgImg, DesktopBgImg, BlockchainBgImg, MobileBgImg, MeetingBgImg } from '../../components/Common/Image'

interface DevelopmentSectionProps {
  active?: boolean,
  positionAbsolute?: boolean
}

const WhatWeDoSection = styled.section`

  .content {
    padding: 120px 75px 120px;
  }

  ${props => props.theme.breakpoints.down("lg")} {
    .content {
      padding: 120px 0 120px;
    }
  }

  ${props => props.theme.breakpoints.down("md")} {
    .button-group {
      padding: 0 !important;
      grid-template-columns: 1fr !important;
      grid-row-gap: 20px;
    }
  }
`

const DevelopmentTypeSectionContainer = styled.section`
  position: relative;
`

const DevelopmentTypeSection = styled.div<DevelopmentSectionProps>`
  opacity: 0;
  transition: all 0.4s ease-in-out;
  z-index: 0;
  pointer-events: none;

  .content {
    text-align: center;
    padding: 120px 135px 120px;

    h2 {
      font: 32px/40px CircularStd-Medium;
      text-transform: uppercase;
      color: ${props => props.theme.colors.white};
    }

    .summary {
      font: 18px/28px Roboto-Regular;
      color: ${props => props.theme.colors.white};
      margin: 30px auto 68px;
      max-width: 824px;
    }

    a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    ${props => props.theme.breakpoints.down("lg")} {
      padding: 120px 0 120px;
    }
  }

  ${props => props.positionAbsolute && `
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  `}
`

const WebDevelopmentSection = styled(DevelopmentTypeSection)<DevelopmentSectionProps>`
  background-image: url(${ExodusBgImg});
  background-position: 50% 38%;
  background-size: cover;
  ${props => props.active && `
    opacity: 1 !important;
    z-index: 1;
    pointer-events: initial;
    button, a {
      z-index: 2;
    }
  `}
`

const MobileDevelopmentSection = styled(DevelopmentTypeSection)<DevelopmentSectionProps>`
  background-image: url(${MobileBgImg});
  background-position: 50% 38%;
  background-size: cover;
  ${props => props.active && `
    opacity: 1 !important;
    z-index: 1;
    pointer-events: initial;
  `}
`

const BlockchainDevelopmentSection = styled(DevelopmentTypeSection)<DevelopmentSectionProps>`
  background-image: url(${BlockchainBgImg});
  background-position: 50% 38%;
  background-size: cover;
  ${props => props.active && `
    opacity: 1 !important;
    z-index: 1;
    pointer-events: initial;
  `}
`

const GameDevelopmentSection = styled(DevelopmentTypeSection)<DevelopmentSectionProps>`
  background-image: url(${GameBgImg});
  background-position: 50% 38%;
  background-size: cover;
  ${props => props.active && `
    opacity: 1 !important;
    z-index: 1;
    pointer-events: initial;
  `}
`

const DesktopDevelopmentSection = styled(DevelopmentTypeSection)<DevelopmentSectionProps>`
  background-image: url(${DesktopBgImg});
  background-position: 50% 38%;
  background-size: cover;
  ${props => props.active && `
    opacity: 1 !important;
    z-index: 1;
    pointer-events: initial;
  `}
`

const WorkFlowSection = styled.section`
  .content {
    text-align: center;
    padding: 120px 0;

    h2 {
      font: 32px/40px CircularStd-Medium;
      text-transform: uppercase;
      color: ${props => props.theme.colors.white};
    }

    .workflow-diagram {
      margin-top: 80px;

      svg {
        width: 100%;
      }
    }

    ${props => props.theme.breakpoints.down("md")} {

      .workflow-diagram {
        margin-top: 0;
      }
    }
  }
`

const HelpUsSection = styled.section`
  background-image: url(${MeetingBgImg});
  background-position: 50% 50%;
  background-size: cover;

  .content {
    padding: 120px 135px;
    text-align: center;

    h4 {
      font: 39px/60px Roboto-Regular;
      color: ${props => props.theme.colors.yellow};
      text-transform: uppercase;
      margin-bottom: 0;
    }

    ${props => props.theme.breakpoints.down('lg')} {
      padding: 120px 0;
    }

    ${props => props.theme.breakpoints.down('sm')} {
      padding: 120px 0;

      h4 {
        font: 30px/40px Roboto-Regular !important;
      }
    }
  }
`

const RelatedCasesSection = styled.div`
  .content {
    padding: 120px 0 120px 135px;
    display: flex;
    overflow: hidden;

    .left {
      width: 370px;
      margin-right: 40px;
      z-index: 10;

      h3 {
        font: 32px/45px Roboto-Bold;
        color: ${props => props.theme.colors.white};
        text-transform: uppercase;
      }

      .sub-title {
        font: 18px/28px Roboto-Bold;
        text-transform: uppercase;
        color: ${props => props.theme.colors.blue50};
      }

      .description {
        font: 16px/28px Roboto-Regular;
        color: ${props => props.theme.colors.white};
      }
    }
  }

  ${props => props.theme.breakpoints.down("lg")} {
    .content {
      padding-left: 0;
    }
  }

  ${props => props.theme.breakpoints.down("sm")} {
    .content {
      flex-direction: column;

      .left {
        width: 100%;
        margin: 0 0 30px 0;
        text-align: center;

        h1 {
          text-align: center;
        }

        .description {
          max-width: 450px;
          margin: 30px auto;
        }
      }
    }
  }
`

const EllipseBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1 !important;

  ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;

    .big-ellipse {
      width: 986.69px;
      height: 986.69px;
      background: rgba(255,255,255,0.05);
      filter: blur(24px);
      border-radius: 50%;
    }

    .small-ellipse {
      width: 496.61px;
      height: 496.61px;
      background: rgba(255,255,255,0.03);
      filter: blur(24px);
      border-radius: 50%;
    }

    &.development-page {
      .ellipse-1 {
        position: absolute;
        left: -30px;
        bottom: 40px;
      }

      .ellipse-2 {
        position: absolute;
        left: 155px;
        bottom: 123px;
      }

      .ellipse-3 {
        position: absolute;
        right: 93px;
        bottom: 106px;
      }

      .ellipse-4 {
        position: absolute;
        top: 247px;
        left: 54px;
      }
    }

    &.development-detail-page {
      .ellipse-1 {
        position: absolute;
        top: 1020px;
        right: -70px;
      }

      .ellipse-2 {
        position: absolute;
        top: 940px;
        right: 93px;
      }

      .ellipse-3 {
        position: absolute;
        top: 1327px;
        right: 27px;
      }

      .ellipse-4 {
        position: absolute;
        bottom: 200px;
        left: -89px;
      }

      .ellipse-5 {
        position: absolute;
        left: 160px;
        bottom: -205px;
      }
    }
  }
`

export {
  WhatWeDoSection,
  DevelopmentTypeSectionContainer,
  DevelopmentTypeSection,
  WorkFlowSection,
  HelpUsSection,
  WebDevelopmentSection,
  MobileDevelopmentSection,
  BlockchainDevelopmentSection,
  GameDevelopmentSection,
  DesktopDevelopmentSection,
  RelatedCasesSection,
  EllipseBackground
}
