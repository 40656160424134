import {
  AppmixerImg,
  AromaticsImg,
  CartKitImg,
  CloudRadarImg,
  CoinMetroImg,
  CriticalStrikeImg,
  DaRumbleImg,
  DataLionImg,
  EtenImg,
  GuardArianImg,
  GyftMastersImg,
  JiminnyImg,
  KiwiImg,
  LisaImg,
  MintrImg,
  MobileCryptoImg,
  MobileOfficeProImg,
  Monsido,
  MycointainerImg,
  NextExchangeImg,
  OnecrateImg,
  ParcelcastImg,
  RegusImg,
  SkyDroneImg,
  SolarinLabImg,
  SquirrelImg,
  SunriseWalletImg,
  UNDRSoundsImg
} from '../../components/Common/Image'
import {IPortfolio} from '../../shared/interfaces'
import {DevelopmentType, IndustryType} from '../../shared/enums'

export const PortfolioData: IPortfolio[] = [
  {
    img: AppmixerImg,
    title: "Appmixer",
    description: "Appmixer is a Saas business platform, which acquire and retain users.\n" +
      " They provide very useful embedded iPaas with an easy-to-use visual workflow automation system and unlimited connectors, it allows customers to focus resources on delivering what customer wants to keep them happy.\n" +
      "We have built this project with powerful backend team within 11months.",
    category: DevelopmentType.WEB,
    industry: IndustryType.CRM
  },
  {
    img: AromaticsImg,
    title: "Aromatics",
    description: "Aromatics is an eCommerce/Healthcare platform to sell extracted oil sourced for Aromatherapists.\n" +
      "Here we developed UX/UI by using the Vue.js with Tailwind CSS for style library.\n" +
      "We used Jira for project management and used Agile methodology with 2 weeks based sprints. We collaborated with the customer's product team and it took 6 months for the first release.",
    category: DevelopmentType.WEB,
    industry: IndustryType.HEALTHCARE
  },
  {
    img: CartKitImg,
    title: "CartKit",
    description: "CartKit is the all in one multi-channel marketing solution. \n" +
      "Other shopify apps can help with specific parts of your marketing but barely make a dent in bringing everything together, but CartKit is the \"all-in-one\" marketing tool for your Shopify store.\n" +
      "CartKit provides Abandoned Cart Campaigns, Email/SMS notifications, Activity Monitor and more!\n",
    category: DevelopmentType.WEB,
    industry: IndustryType.ECOMMERCE
  },
  {
    img: CloudRadarImg,
    title: "Cloudradar",
    description: "Wherever we worked, we set up or replaced a monitoring tool that had gotten too old and complex to maintain.\n" +
      "Weeding through complex configurations and interfaces and struggling with unsupported legacy systems we became increasingly frustrated.\n" +
      "So we approached monitoring from a new angle - to make it really easy to use. Like Office 365 and Gsuite replaced Exchange. What used to require expert knowledge is now done in a fraction of time.\n" +
      "CloudRadar aims at getting you monitored without making it a job in itself. Simplified to the core, saving time and hassle. Allowing teams to enjoy it - which actually makes them use it.\n",
    category: DevelopmentType.WEB,
    industry: IndustryType.OTHERS
  },
  {
    img: CoinMetroImg,
    title: "CoinMetro",
    description: "CoinMetro is a cryptocurrency exchange and trading project based on Estonia.\n" +
      "The project’s major functionalities are;\n" +
      "● Swap between crypto & fiat \n" +
      "● Fiat deposits & withdrawals in AUD, EUR, GBP and USD.\n" +
      "● Instant ACH, Instant SEPA, Faster Payments, and SWIFT.\n" +
      "\n" +
      "The project includes several small projects such as user backoffice, exchange product, admin dashboard, and several micro services to provide user deposit/withdraw, orderbook sync, liquidity provider integration and KYC/AML service integration.\n" +
      "We have developed this platform from scratch and completed the project for 1 year and half.\n" +
      "We’ve led the platform development from the design, user story analysis, technology architecture design, actual programming in both frontend, backend and blockchain smart contracts.\n",
    category: [DevelopmentType.WEB, DevelopmentType.BLOCKCHAIN],
    industry: IndustryType.OTHERS
  },
  {
    img: CriticalStrikeImg,
    title: "Critical Strike CS",
    description: "Critical Strike is a fast-paced modern multiplayer FPS counter terrorist game.\n" +
      "Cooperated with Vertigo game development company.\n" +
      "Created multi-player networking module in unity\n" +
      "Unity3D animation and shading models\n" +
      "In app purchase items and Ads, leaderboard implementation\n" +
      "Game test automation and publish android and iOS game in appstore\n" +
      "Unity shader and animation, Physics engine, C# scripting, NGUI\n" +
      "Navigation AI and pathfinding, Audio and sound environment\n.",
    category: [DevelopmentType.MOBILE, DevelopmentType.GAME],
    industry: IndustryType.OTHERS
  },
  {
    img: MobileCryptoImg,
    title: "Mobile Wallet",
    description: "Divi is minted through a Proof of Stake consensus protocol using both staking and Divi Nodes to secure and verify transactions.\n" +
      "\n" +
      "The Divi network is fully transparent, audited by third-party security professionals and secured against attacks. You can trust that your transactions are secure.\n" +
      "\n" +
      "We never have access to your private keys or backup phrases, meaning you are fully in control of your digital assets. Our crypto wallet integrates the latest biometric security and 2FA authentication.\n" +
      "\n" +
      "*Divi Lottery - To participate in the lottery, all you need is to stake with UTXO that is > 10000 Divi. This value can be updated by DVS (multivalue sporks. Full details can be found here.",
    category: [DevelopmentType.MOBILE, DevelopmentType.BLOCKCHAIN],
    industry: [IndustryType.DAPP, IndustryType.FINANCE]
  },
  {
    img: DaRumbleImg,
    title: "DaRumble",
    description: "DaRumble is a social mobile app for car enthusiasts where they can upload photos of their cars, and share events between users.\n" +
      "It also allows users upload video using phone camera directly to Vimeo, and share via social media or their followers inside of app.\n" +
      "This mobile app also has got real time chatting functions between users.\n" +
      "Global Feed, Local Feed, Messages, and My Account are main pages of this mobile application.\n" +
      "Global Feed includes international events, and local feed includes local events in their city.\n",
    category: DevelopmentType.MOBILE,
    industry: IndustryType.SOCIAL
  },
  {
    img: DataLionImg,
    title: "DataLion",
    description: "DataLion analysis software can evaluate large amounts of data quickly and easily and visualize even complex information in an elegant and understandable manner. The analyzes can be carried out faster, less error-prone and the results better. The insights gained in this way support you directly in decision-making and move your business forward.",
    category: DevelopmentType.WEB,
    industry: IndustryType.ENTERPRISE
  },
  {
    img: EtenImg,
    title: "Eten",
    description: "Eten is a food delivery platform.\n" +
      "They list thousands of food products on their website and let customers search their food based on the location, title, category.\n" +
      "They provide online payment and on-request delivery services.\n" +
      "We worked on this project as a full-stack developer team and completed the project within 8 months.\n",
    category: DevelopmentType.WEB,
    industry: IndustryType.OTHERS
  },
  {
    img: GuardArianImg,
    title: "Guardarian",
    description: "Licensed fiat to crypto gateway with more than 200 coins. White label user-friendly SEPA purchase solution.",
    category: [DevelopmentType.WEB, DevelopmentType.BLOCKCHAIN],
    industry: IndustryType.FINANCE
  },
  {
    img: GyftMastersImg,
    title: "Gyftmasters",
    description: "Gyftmaster is to send gifts for celebrations, special occasions, and whenever you are in the mood of gifting.\n" +
      "We built this web and mobile application from scratch as a front-end developer and implemented these functionalities: integrated the 3rd party payment management, customize the map-box, real-time social chatting and post the blog and so on.\n",
    category: [DevelopmentType.WEB, DevelopmentType.MOBILE],
    industry: IndustryType.OTHERS
  },
  {
    img: JiminnyImg,
    title: "Jiminny",
    description: "Jiminny is a CRM platform to help sales teams by empowering your sales reps to win together with: the sales coaching platform that records, transcribes and analyses your team's customer conversations so that you can collaborate, coach and grow effortlessly.\n" +
      "\tIt is using many social services such as Zoom, Hubspot, Pipedrive, Google Meet, Slack and more to help CRM teams to manage their customers. We built this app from scratch for Frontend and Backend and it took 1 year to complete.",
    category: DevelopmentType.WEB,
    industry: IndustryType.CRM
  },
  {
    img: KiwiImg,
    title: "Kiwi.com",
    description: "Kiwi.com is a reservation / booking platform. Kiwi.com is building the world's first Virtual Global Supercarrier, combining air and ground transport, ride-hailing, and micro-mobility, so you can get from any A to any B easily.\n" +
      "We worked on this project as a web full stack team and completed the project within 8 months.\n",
    category: DevelopmentType.WEB,
    industry: IndustryType.OTHERS
  },
  {
    img: LisaImg,
    title: "Hello-Lisa",
    description: "Hello Lisa is an Ecommerce platform which has live shopping experiences and practise..\n" +
      "They provide live video chatting and live shopping functionality. We worked on this project as a web full stack development team, completed the main functionalities of projects within 1.5 years and maintaining it now.\n",
    category: DevelopmentType.WEB,
    industry: IndustryType.CRM
  },
  {
    img: MintrImg,
    title: "Mintr",
    description: "MINTR is the first NFT cross chain marketplace for B2B and B2C with art galleries, museums, auction places and workshops.\n" +
      "MINTR is aiming to be the one-stop-shop in NFT, from creation your own NFT’s, Bidding on the most famous artworks and enjoying a virtual museum tour experience all in the palm of your hands.\n",
    category: [DevelopmentType.WEB, DevelopmentType.BLOCKCHAIN],
    industry: IndustryType.DAPP
  },
  {
    img: MobileOfficeProImg,
    title: "Mobile Office Pro",
    description: "Mobile Office Pro App for Network Marketing Professionals.\n" +
      "Created iOS and android applications with Flutter.\n" +
      "Firebase Cloud Messaging, Crashlystics and Authentication integration\n" +
      "Published apple and google app store from Flutter.\n" +
      "Implemented Nodejs REST API backend for REST API service\n.",
    category: DevelopmentType.MOBILE,
    industry: IndustryType.ENTERPRISE
  },
  {
    img: Monsido,
    title: "Monsido",
    description: "Monsido is an easy-to-use platform that includes automated website accessibility testing and website maintenance solutions to improve your website user experience.\n" +
      "The old version has been built on WordPress. In order to improve the site performance and SEO, we have built the landing page of this project using OctoberCMS.\n",
    category: DevelopmentType.WEB,
    industry: IndustryType.CRM
  },
  {
    img: MycointainerImg,
    title: "MyCointainer",
    description: "MyCointainer is the best investment platform and a solution designed especially for newcomers to enable easy access to the crypto world.\n" +
      "The platform aims to give users professional, secure, and easy cryptocurrency staking services and simplify the buying and selling of digital currencies at the best possible rates through its built-in cryptocurrency exchange.\n" +
      "We built MyCointainer for the web and mobile version as senior Full Stack developers and launched a web application within 6 months and posted the mobile version to the google and app store successfully within 1 year.\n",
    category: [DevelopmentType.WEB, DevelopmentType.MOBILE],
    industry: IndustryType.FINANCE
  },
  {
    img: NextExchangeImg,
    title: "Next.exchange",
    description: "platform is a hybrid decentralized cryptocurrency exchange for trading cryptocurrencies and tokens in a fast and secure way.\n" +
      "They have fully paired Verge, Rupee, Electra and over 100+ ERC20 tokens with other crypto and fiat.\n" +
      "This platform is capable of obtaining high trading volumes and generating substantial profits. Pool trading helps spread trader's risk while investing in the top 30 cryptocurrencies on the market.\n" +
      "What distinguishes NEXT.exchange from other digital asset exchanges occupying this developing industry?\n" +
      "The NEXT.exchange platform patches up key issues that are common amongst other platforms occupying the crypto-sphere. Most notably:\n" +
      "\t- Lowest trading fees in the market\n" +
      "\t- Zero fees imposed on a new coin or token listings\n" +
      "\t- Exceptional transaction speed and scalability through use of new technologies\n" +
      "\t- Our own blockchain capable of providing over 100,000 transactions per second\n" +
      "\t- Instant ERC20 token integrations\n" +
      "\t- Listing of non-ERC20 blockchains. Such as Electroneum and Skycoin\n" +
      "\t- Registered as an official Dutch limited liability company\n" +
      "\t- Audited by one of the Big 4 Accounting firms\n" +
      "\t- BTC, ETH, LTC, NEXT and fiat pairings\n.",
    category: [DevelopmentType.WEB, DevelopmentType.BLOCKCHAIN],
    industry: IndustryType.DAPP
  },
  {
    img: OnecrateImg,
    title: "Onecrate",
    description: "Onecrate is an interactive social organization platform(ISOP).\n" +
      " They build customer ISOP(Interactive Social Org Platform), an interactive org chart built on the foundation of a company job portfolio, and increase employee retention rate by allowing employees to discover growth opportunities.\t\n" +
      "We have built this project with excellent scrum team within 10 months\n",
    category: DevelopmentType.WEB,
    industry: IndustryType.SOCIAL
  },
  {
    img: ParcelcastImg,
    title: "Parcelcast",
    description: "Parcelcast is a global shipping service which is integrated with carriers such as DHL, USPS, UPS and so on. \n" +
      "Customers can ship a buch shipments quickly and easily. \n" +
      "Especially parcelcast is providing global shipping in the world.\n",
    category: DevelopmentType.WEB,
    industry: IndustryType.ENTERPRISE
  },
  {
    img: RegusImg,
    title: "Regus",
    description: "Regus is the leading global workspace provider. With office, co-working and meeting spaces in practically every city in the world.",
    category: DevelopmentType.WEB,
    industry: IndustryType.ENTERPRISE
  },
  {
    img: SkyDroneImg,
    title: "Sky-Drone",
    description: "Sky-drone is the most advanced AI Drone avionics platform for enterprise.\n" +
      "Control drone flights remotely on the platform.\n" +
      "Get live streaming video from a drone at realtime.\n" +
      "Create way points of drone flight in the 3D map.\n",
    category: DevelopmentType.WEB,
    industry: IndustryType.ENTERPRISE
  },
  {
    img: SolarinLabImg,
    title: "SolarinLab",
    description: "SolarinLab is an Ecommerce platform based on Blockchain technologies.\n" +
      "They sell their custom mobile devices which are based on Blockchain technologies for all of datas like contacts, call histories, media data, and etc to get encrypted.\n" +
      "We worked on this project as a web frontend team and completed the project within 5 months.\n",
    category: [DevelopmentType.WEB, DevelopmentType.BLOCKCHAIN],
    industry: IndustryType.DAPP
  },
  {
    img: SquirrelImg,
    title: "Squirrel",
    description: "Squirrel is a Social network platform which server social media platform, work sharing station and communication swiss army knife. Especially, it is a comprehensive, all-in-one platform offering streamlined communication and networking tools for groups of all sizes",
    category: [DevelopmentType.WEB],
    industry: IndustryType.SOCIAL
  },
  {
    img: SunriseWalletImg,
    title: "Sunrise Wallet",
    description: "Sunrise Wallet is a wallet system for customers to be able to purchase products with the cryptocurrency payments. It supports top 10 cryptocurrencies such as BTC, ETH, DOGE, XRP, and etc.",
    category: DevelopmentType.WEB,
    industry: [IndustryType.FINANCE, IndustryType.OTHERS, IndustryType.DAPP]
  },
  {
    img: UNDRSoundsImg,
    title: "UNDRGRND Sounds",
    description: "UNDRGRND Sounds is an eCommerce platform based on Nuxt.js and Shopify.\n" +
      "Their main product is underground music and they wanted to make their own unique platform which could be leading their industry.\n" +
      "We used Nuxt.js and Shopify together with Contentful as a CMS to make it beautiful, user-friendly and scalable.\n",
    category: DevelopmentType.WEB,
    industry: IndustryType.ECOMMERCE
  }
]
