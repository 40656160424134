import * as types from '../actionTypes'
import { DevelopmentType } from '../../shared/enums'

const initialState = {
  selectedTab: 'web' as DevelopmentType
}

const developmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_DEVELOPMENT_TAB:
      return {
        ...state,
        selectedTab: action.payload
      };

    default:
      return state;
  }
}

export default developmentReducer
