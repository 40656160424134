import { MemberNew1Img, MemberNew2Img, MemberNew3Img, MemberNew4Img, MemberNew5Img, MemberNew6Img } from '../../components/Common/Image'

export const managementData = [
  {
    name: 'ALEKSANDAR MRAKOVIC',
    img: MemberNew1Img,
    job: 'Chief Executive Officer, Co-founder',
    description: 'Alexandar is the highest-ranking executive in a company and is responsible for the overall success of the business. He sets the company\'s vision and strategy, and oversee all aspects of its operations.'
  },
  {
    name: 'DANILO IVKOVIC',
    img: MemberNew3Img,
    job: 'Chief Technical Officer, Co-founder',
    description: 'Danilo is in charge of production and manages the day-to-day operations of Pixelconn production offices. This includes developing HR policy and legal compliance, hiring key employees and implementing IP protection best-practices.\n\nDanilo is startup enthusiast, inspiring and running Pixelconn’s successful startup Eventicious.'
  },
  {
    name: 'DJURADJ SAMARDZIC',
    img: MemberNew4Img,
    job: 'Co-founder',
    description: 'Djuradj plays a vital role in the success of Pixelconn. By developing and implementing a clear vision and strategy, and by effectively managing resources, he helps to ensure that the organization achieves its goals and objectives.'
  },
  {
    name: 'OLYA SIMIC',
    img: MemberNew2Img,
    job: 'Product Manager',
    description: 'Olya is responsible for the vision, strategy, and execution of the product. They work with stakeholders to gather requirements, define features, and prioritize work.'
  },
  {
    name: 'MARIO LOVRIC',
    img: MemberNew5Img,
    job: 'Software Engineer',
    description: 'MARIO test the software to ensure that it meets the requirements and is free of defects. They use a variety of testing methods to identify and fix bugs.'
  },
  {
    name: 'MILOS NIKIC',
    img: MemberNew6Img,
    job: 'DevOps Engineer',
    description: 'Milos automates the software development and deployment process. They work with the software development team to ensure that the software is released quickly and reliably.'
  },
]
