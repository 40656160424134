import styled from 'styled-components'
import { Input as AInput, Button as AButton } from 'antd'

const ContactUsSection = styled.section`
  .content {
    padding: 120px 135px 120px;

    .main-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ${props => props.theme.breakpoints.down("sm")} {
      h1 {
        text-align: center;
      }

      .main-content {
        flex-direction: column;
        padding: 0 20px;

        > div {
          width: 100%;
        }
      }
    }

    ${props => props.theme.breakpoints.down(400)} {
      .main-content {
        padding: 0;
      }
    }
  }

  ${props => props.theme.breakpoints.down("lg")} {
    .content {
      padding: 120px 0;
    }
  }
`

const Title = styled.h1`
  font: 48px/55px Roboto-Bold;
  color: ${props => props.theme.colors.white};
  text-transform: uppercase;

  ${props => props.theme.breakpoints.down("sm")} {
    font: 40px/48px Roboto-Bold;
  }
`

const Left = styled.div`
  flex: 1;
  width: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
`

const Right = styled.div`
  flex: 1;
  width: 0;
  max-width: 350px;

  .social-icons {
    display: flex;
    justify-content: space-between;
  }

  ${props => props.theme.breakpoints.down("sm")} {
    max-width: 500px;
    margin-top: 40px;

    p {
      text-align: center;
    }

    .social-icons {
      max-width: 300px;
      margin: 0 auto;
    }
  }
`

const Input = styled(AInput)`
  background-color: ${props => props.theme.colors.grey200} !important;
  border: none !important;
  color: ${props => props.theme.colors.white} !important;
  padding: 8px 20px !important;
  margin-bottom: 26px !important;
  border-radius: 6px !important;
  font: 14px/30px Roboto-Light !important;

  &::placeholder {
    color: ${props => props.theme.colors.white} !important;
  }

  &:first-child {
    margin-bottom: 16px !important;
  }
`

const TextArea = styled(AInput.TextArea)`
  background-color: ${props => props.theme.colors.grey200} !important;
  border: none !important;
  color: ${props => props.theme.colors.white} !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font: 14px/30px Roboto-Light !important;
  margin-bottom: 38px !important;

  &::placeholder {
    color: ${props => props.theme.colors.white} !important;
  }
`

const SubTitle = styled.h1`
  color: ${props => props.theme.colors.white};
  margin-bottom: 20px;
  font: 32px/40px Roboto-Bold;
  text-transform: uppercase;
`

const Description = styled.p`
  color: white;
  margin-bottom: 54px;
  font: 16px/28px Roboto-Regular;
`

const SocialIconButton = styled(AButton)`
  padding: 0 !important;
  height: unset !important;
`

export { ContactUsSection, Title, Input, Left, Right, TextArea, SubTitle, Description, SocialIconButton }
