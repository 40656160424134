import styled from 'styled-components'

const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 461px;
  max-width: 400px;
  padding: 25px 25px 32px;
  text-align: center;
  border-radius: 10px;
  border: solid 1px ${props => props.theme.colors.blue50};
  background: linear-gradient(180deg, #DEDEDF, 15%, transparent 40%);
  box-shadow: 0 2px 6px rgba(47,83,151,0.1);
  animation-duration: 0.5s;
  animation-name: slideIn;
  transition: all ease 0.3s;

  img {
    width: 250px;
    height: 250px;
    margin-bottom: 20px;
  }

  .name {
    color: ${props => props.theme.colors.white};
    font: 22px/29px Roboto-Medium;
    margin-bottom: 10px;
  }

  .job {
    font: 14px/19px Roboto-Medium;
    color: ${props => props.theme.colors.grey500};
    margin: 0;
    text-transform: uppercase;
  }

  .description {
    font: 12px/19px Roboto-Medium;
    margin: 35px 0 28px;
    text-align: left;
    color: ${props => props.theme.colors.white};
    white-space: pre-wrap;
  }

  .footer {
    flex: 1;
    display: flex;
    align-items: flex-end;
    width: 100%;

    button {
      margin: 0 auto;
    }
  }

  &.back-side {
    text-align: left;
    color: ${props => props.theme.colors.white};
    align-items: flex-start;

    .name {
      text-align: left;
      margin-top: 20px;
    }

    .job {
      text-align: left;
      color: ${props => props.theme.colors.white};
    }

    h6 {
      font-size: 16px;
      color: ${props => props.theme.colors.white};
      margin-top: 20px;
    }

    .skill {
      margin: 0;
    }

    ul {
      padding-left: 18px;

      li {
        font: 16px/20px CircularStd-Book;
      }
    }
  }

  @keyframes slideIn {
    from {
      transform: rotateY(180deg);
    }

    to {
      transform: rotateY(0deg);
    }
  }
`

export { Card }
