import styled from 'styled-components'
import AwesomeSlider from 'react-awesome-slider'
import { Button } from '../Common/Button'

const Container = styled.div`
  ${props => props.theme.breakpoints.down('sm')} {
    margin-top: 40px;
  }
`

const Slider = styled(AwesomeSlider)`
  max-height: 630px;

  .awssld__content {
    background-color: transparent !important;
    overflow: initial !important;
    display: block !important;
    max-height: 630px;
  }

  .awssld__wrapper {
    overflow: initial !important;
  }

  .awssld__box {
    max-height: 630px;
  }

  .awssld__bullets {
    button {
      background-color: ${props => props.theme.colors.light300} !important;
      width: 10px !important;
      height: 10px !important;
      display: block;
      border-radius: 50%;
      margin: 5px 10px !important;
    }

    .awssld__bullets--active {
      transform: none !important;
      background-color: transparent !important;
      background-image: radial-gradient(${props => props.theme.colors.yellow} 21%, transparent 34%);
      border: 1px solid ${props => props.theme.colors.yellow} !important;
      width: 26px !important;
      height: 26px !important;
    }
  }
`

const ManagementCardList = styled.div`
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5px;
  padding-left: 30px;
  margin-top: 40px;
`

const MobileList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  padding: 0 20px;

  ${props => props.theme.breakpoints.down('xs')} {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;

    > div {
      margin: 0 auto;
    }
  }

  ${props => props.theme.breakpoints.down(400)} {
    padding: 0;
  }
`

const ManagementCard = styled.div`
  padding: 40px 0;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.blue50};
  background: linear-gradient(180deg, #DEDEDF, 15%, transparent 40%);
  transform: skewX(-6deg);
  transition: opacity ease-in-out 0.2s;

  .card-container {
    transform: skewX(6deg);

    .img-container {
      img {
        max-height: 380px;
      }
    }

    .card-content {
      margin-top: 10px;
      padding-left: 15px;
      padding-right: 30px;

      .name {
        font: 16px/20px CircularStd-Bold;
        color: ${props => props.theme.colors.white};
        margin: 0;
        text-transform: uppercase;
      }

      .divider {
        width: 72px;
        height: 4px;
        background: ${props => props.theme.colors.yellow};
        margin: 10px 0 25px;
      }

      .job {
        font: 16px/22px Roboto-Regular;
        color: ${props => props.theme.colors.blue50};
        margin: 0;
      }

      .description {
        font: 14px/22px Roboto-Regular;
        color: ${props => props.theme.colors.white};
        margin: 0;
        height: 0;
        white-space: pre-wrap;
        overflow: hidden;
      }
    }
  }

  &:hover {
    ${props => props.theme.breakpoints.up('md')} {
      margin: -30px;
      z-index: 10 !important;
      background: linear-gradient(125deg, ${props => props.theme.colors.blue600}, ${props => props.theme.colors.blue400});
      border: 3px solid ${props => props.theme.colors.blue300};
      padding: 20px 0;

      .img-container {
        padding-left: 30px;

        img {
          height: 335px;
        }
      }

      .name {
        font: 22px/20px CircularStd-Bold !important;
      }

      .job {
        display: none;
      }

      .description {
        height: inherit !important;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 7;
        -moz-box-orient: vertical;
      }
    }
  }

  ${props => props.theme.breakpoints.down('md')} {
    padding-bottom: 20px !important;
    min-height: 485px;

    .img-container {
      padding-left: 50px;

      img {
        max-height: 300px !important;
      }
    }

    &:hover {
      padding-bottom: 20px !important;

      img {
        max-height: 250px !important;
      }
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    transform: initial;
    padding: 20px 0 !important;

    .card-container {
      transform: initial;

      .img-container {
        padding-left: 0 !important;
        text-align: center;

        img {
          max-height: 250px !important;
        }
      }

      .card-content {
        padding: 0 15px;

        .name {
          font: 22px/20px CircularStd-Bold !important;
          text-align: center;
        }

        .description {
          height: inherit !important;
          margin-top: 10px !important;
          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 7;
          -webkit-box-orient: vertical;
          display: -moz-box !important;
          -moz-line-clamp: 7;
          -moz-box-orient: vertical;
          text-align: center;
        }

        .divider {
          margin: 10px auto 25px;
        }

        .job {
          text-align: center;
        }
      }
    }
  }

  ${props => props.theme.breakpoints.down('xs')} {
    max-width: 400px;
  }
`

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
`

const ArrowButton = styled(Button)`
  border-radius: 10px !important;
  width: 48px !important;
  height: 48px !important;

  &:disabled {
    background-color: #8E8E8E !important;
    border-color: transparent !important;

    svg {
      fill: ${props => props.theme.colors.white};
    }
  }
`

export {
  Container,
  Slider,
  ManagementCardList,
  ManagementCard,
  Toolbar,
  ArrowButton,
  MobileList
}
