import styled from 'styled-components'

const WhatWeDoSection = styled.section`
  .content {
    padding: 120px 75px 120px;
  }

  ${props => props.theme.breakpoints.down("lg")} {
    .content {
      padding: 120px 0 120px;
    }
  }

  ${props => props.theme.breakpoints.down("md")} {
    .button-group {
      padding: 0 !important;
      grid-template-columns: 1fr !important;
      grid-row-gap: 20px;
    }
  }
`

const SkillsSection = styled.section`

  .content {
    padding: 30px 75px;

    ${props => props.theme.breakpoints.down("lg")} {
      padding: 30px 20px;
    }

    h4 {
      font: 32px/40px CircularStd-Medium;
      color: ${props => props.theme.colors.white};
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 50px;
    }

    .skill-list-container {
      overflow: hidden;

      .loading-container {
        height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .skill-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        margin-top: -90px;

        ${props => props.theme.breakpoints.down("sm")} {
          grid-template-columns: 1fr 1fr 1fr 1fr;

          .skill-list-item {
            &:nth-child(4n) {
              border-right: none !important;
            }
          }
        }

        ${props => props.theme.breakpoints.down("xs")} {
          grid-template-columns: 1fr 1fr;

          .skill-list-item {
            &:nth-child(2n) {
              border-right: none !important;
            }
          }
        }

        .skill-list-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          min-height: 180px;
          justify-content: space-between;
          border-right: 1px solid ${props => props.theme.colors.white}80;
          padding-top: 90px;

          span {
            font: 22px/25px Roboto-Bold;
            color: ${props => props.theme.colors.white}60;
          }

          ${props => props.theme.breakpoints.up("md")} {
            &:nth-child(6n) {
              border-right: none;
            }
          }
        }
      }
    }
  }

`

const OurExpertsSection = styled.section`
  .content {
    padding: 120px 135px 30px;

    ${props => props.theme.breakpoints.down("lg")} {
      padding: 120px 0 30px;
    }

    .loading-container {
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .expert-card-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-row-gap: 32px;
      grid-column-gap: 27px;
      margin-top: 95px;

      ${props => props.theme.breakpoints.down("sm")} {
        grid-template-columns: 1fr 1fr;
      }

      ${props => props.theme.breakpoints.down("sm")} {
        grid-template-columns: 1fr;

        > div {
          margin: 0 auto;
        }

        .description {
          text-align: center !important;
        }
      }
    }
  }
`

const EllipseBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1 !important;

  ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;

    .big-ellipse {
      width: 986.69px;
      height: 986.69px;
      background: rgba(255, 255, 255, 0.05);
      filter: blur(24px);
      border-radius: 50%;
    }

    .small-ellipse {
      width: 496.61px;
      height: 496.61px;
      background: rgba(255, 255, 255, 0.03);
      filter: blur(24px);
      border-radius: 50%;
    }

    .ellipse-1 {
      position: absolute;
      left: 54px;
      top: 254px;
    }

    .ellipse-2 {
      position: absolute;
      left: -90px;
      top: 1409px;
    }

    .ellipse-3 {
      position: absolute;
      left: 160px;
      bottom: 1624px;
    }
  }
`

export {
  WhatWeDoSection,
  SkillsSection,
  OurExpertsSection,
  EllipseBackground
}
