import styled from 'styled-components'

import { CompanyBgImg } from '../../components/Common/Image'

const CompanySection = styled.section`
  .content {
    padding: 120px 135px;

    ${props => props.theme.breakpoints.down('lg')} {
      padding: 120px 0;
    }

    ${props => props.theme.breakpoints.down('sm')} {
      h2 {
        font: 30px/40px Roboto-Regular !important;
      }
    }

    h2 {
      font: 39px/60px Roboto-Regular;
      color: ${props => props.theme.colors.white};
      text-transform: uppercase;
      text-align: center;
      margin-top: 30px;
    }
  }
`

const OurValuesSection = styled.section`
  background-image: url(${CompanyBgImg});
  background-position: 50% 30%;
  background-size: cover;

  .content {
    padding: 120px 135px;
    text-align: center;

    .title {
      font: 32px/50px Roboto-Medium;
      color: ${props => props.theme.colors.white};
      text-transform: uppercase;
      margin: 0;
    }

    .description {
      font: 20px/30px Roboto-Regular;
      color: ${props => props.theme.colors.white};
      margin: 40px 0 30px;
    }

    .values-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 15px;
      grid-column-gap: 100px;

      .value-item {
        display: flex;
        align-items: center;

        svg {
          margin-top: 5px;
          min-width: 24px !important;
          min-height: 24px !important;
        }

        span {
          font: 16px/30px Roboto-Regular;
          color: ${props => props.theme.colors.white};
          margin-left: 10px;
        }
      }
    }

    ${props => props.theme.breakpoints.down('lg')} {
      padding: 120px 0;

      .values-list {
        grid-column-gap: 10px;

        .value-item span {
          white-space: nowrap;
        }
      }
    }

    ${props => props.theme.breakpoints.down('sm')} {
      .values-list {
        grid-template-columns: 1fr;
        padding-left: 145px;
      }
    }

    ${props => props.theme.breakpoints.down('xs')} {
      .values-list {
        padding-left: 15px;

        .value-item {
          align-items: flex-start;;

          span {
            white-space: initial !important;
            text-align: center !important
          }
        }
      }
    }
  }
`

const ManagementSection = styled.section`
  .content {
    padding: 120px 135px;

    .title {
      font: 32px/50px Roboto-Medium;
      color: ${props => props.theme.colors.white};
      text-transform: uppercase;
      margin: 0;
      text-align: center;
    }

    ${props => props.theme.breakpoints.down('lg')} {
      padding: 120px 0 110px;
    }
  }
`

const OurPrinciplesSection = styled.section`
  .content {
    padding: 30px 135px;
    text-align: center;

    .title {
      font: 32px/50px Roboto-Medium;
      color: ${props => props.theme.colors.white};
      text-transform: uppercase;
      margin: 0;
    }

    .description {
      font: 20px/30px Roboto-Regular;
      color: ${props => props.theme.colors.white};
      margin: 40px 0 80px;
    }

    .principle-card-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 14px;
    }

    ${props  => props.theme.breakpoints.down('lg')} {
      padding: 0;
    }

    ${props  => props.theme.breakpoints.down('sm')} {
      .description {
        padding: 0 20px;
      }

      .principle-card-list {
        grid-template-columns: 1fr;
        grid-row-gap: 40px;

        > div {
          margin: 0 auto;
        }
      }
    }
  }
`

const PrincipleCard = styled.div`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;

  .header {
    width: 100%;
    height: 150px;
    padding: 50px 50px 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background: #000320;

    .title {
      font: 26px/35px Roboto-Bold;
    }

    .divider {
      width: 100%;
      height: 3px;
      background: #2A407C;
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    max-width: 400px;

    .title {
      text-align: center;
      width: 100%;
    }
  }

  ${props => props.theme.breakpoints.down(400)} {
    width: 100%;
    max-width: unset;
  }
`

const EllipseBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1 !important;

  ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;

    .big-ellipse {
      width: 986.69px;
      height: 986.69px;
      background: rgba(255, 255, 255, 0.05);
      filter: blur(24px);
      border-radius: 50%;
    }

    .small-ellipse {
      width: 496.61px;
      height: 496.61px;
      background: rgba(255, 255, 255, 0.03);
      filter: blur(24px);
      border-radius: 50%;
    }

    .ellipse-1 {
      position: absolute;
      top: 1882px;
      left: 160px;
    }

    .ellipse-2 {
      position: absolute;
      top: 1428px;
      right: -75px;
    }

    .ellipse-3 {
      position: absolute;
      left: -427px;
      bottom: 890px;
    }

    .ellipse-4 {
      position: absolute;
      left: -78px;
      bottom: 316px;
    }

    .ellipse-5 {
      position: absolute;
      right: -80px;
      bottom: 634px;
    }
  }
`

export {
  CompanySection,
  OurValuesSection,
  ManagementSection,
  OurPrinciplesSection,
  PrincipleCard,
  EllipseBackground
}
