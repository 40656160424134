import styled from 'styled-components'
import { Input as AInput } from 'antd'

import { ContactsBgImg } from '../../components/Common/Image'

const MainContent = styled.div`
  padding: 120px 0 120px;
  flex: 1;
`

const ContactsSection = styled.div`
  background-image: url(${ContactsBgImg});
  background-position: 50% 76%;
  background-size: cover;
  margin-top: 80px;

  .content {
    padding: 33px 135px 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;

    .left-panel {
      .description {
        font: 18px/28px Roboto-Regular;
        color: ${props => props.theme.colors.white};
        margin-bottom: 32px;
        text-align: left;
        white-space: pre-wrap;
      }

      .input-group {
        text-align: center;
      }
    }

    .right-panel {
      .address {
        display: flex;

        > div:first-child {
          flex: 1;
          width: 0;
        }

        span, p {
          font: 14px/22px Roboto-Regular;
          color: ${props => props.theme.colors.white};
        }

        p {
          margin-left: 35px;
          margin-bottom: 0;
        }

        .address-item {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          &.bold {
            span {
              font: 14px/22px Roboto-Bold !important;
            }
          }

          svg {
            margin-right: 15px;
          }
        }

        ${props => props.theme.breakpoints.down(450)} {
          text-align: center;

          .address-item {
            justify-content: center;
          }
        }
      }

      .map-view {
        margin-top: 10px;
      }
    }
  }

  ${props => props.theme.breakpoints.down('lg')} {
    .content {
      padding: 33px 35px 40px;
    }
  }

  ${props => props.theme.breakpoints.down('md')} {
    .content {
      padding: 33px 0 40px;
    }

    .address {
      flex-direction: column-reverse;

      > div {
        flex: initial !important;
        width: 100% !important;
      }

      .address-item {
        margin-bottom: 16px !important;
      }
    }

    .map-view {
      margin-top: 13px !important;

      img {
        width: 100%;
      }
    }
  }

  ${props => props.theme.breakpoints.down('sm')} {
    .content {
      grid-template-columns: 1fr;
      grid-row-gap: 50px;

      .description {
        text-align: center !important;
      }
    }
  }
`

const Input = styled(AInput)`
  background-color: rgba(255,255,255,0.6) !important;
  border: none !important;
  color: ${props => props.theme.colors.blue200} !important;
  padding: 8px 20px !important;
  margin-bottom: 26px !important;
  border-radius: 6px !important;
  font: 14px/30px Roboto-Light !important;

  &::placeholder {
    color: ${props => props.theme.colors.blue200} !important;
  }

  &:first-child {
    margin-bottom: 16px !important;
  }
`

const TextArea = styled(AInput.TextArea)`
  background-color: rgba(255,255,255,0.6) !important;
  border: none !important;
  color: ${props => props.theme.colors.blue200} !important;
  padding: 8px 20px !important;
  border-radius: 6px !important;
  font: 14px/30px Roboto-Light !important;
  margin-bottom: 38px !important;

  &::placeholder {
    color: ${props => props.theme.colors.blue200} !important;
  }
`

const EllipseBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1 !important;

  ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }

  .content {
    position: relative;
    width: 100%;
    height: 100%;

    .big-ellipse {
      width: 986.69px;
      height: 986.69px;
      background: rgba(255, 255, 255, 0.05);
      filter: blur(24px);
      border-radius: 50%;
      position: absolute;
      top: 245px;
      left: 54px;
    }
  }
`

export {
  MainContent,
  ContactsSection,
  Input,
  TextArea,
  EllipseBackground
}
