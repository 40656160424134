import React from 'react'
import { useTranslation } from 'react-i18next'

import * as S from './styles'
import { SectionTitle } from '../../components/Common/CommonStyled'
import { Button } from '../../components/Common/Button'
import { LocationIcon, EmailIcon, PhoneIcon } from '../../components/Common/Icon'
import { LocationMapImg } from '../../components/Common/Image'

const Contacts = () => {
  const { t } = useTranslation('contacts')

  return (
    <React.Fragment>
      <S.MainContent>
        <div className='container'>
          <SectionTitle>{t('title')}</SectionTitle>
        </div>
        <S.ContactsSection>
          <div className='container'>
            <div className='content'>
              <div className='left-panel'>
                <p className='description'>{t('description')}</p>
                <div className='input-group'>
                  <S.Input placeholder={t('input.name')} />
                  <S.Input placeholder={t('input.email')} />
                  <S.TextArea placeholder={t('input.enterText')} autoSize={{ minRows: 5, maxRows: 5 }} />
                  <Button>{t('send')}</Button>
                </div>
              </div>
              <div className='right-panel'>
                <div className='address'>
                  <div>
                    <div className='address-item bold'>
                      <LocationIcon />
                      <span>{t('city')}</span>
                    </div>
                    <p>{t('office')}</p>
                    <p>{t('street')}</p>
                  </div>
                  <div>
                    <div className='address-item'>
                      <PhoneIcon />
                      <span>{t('phone')}</span>
                    </div>
                    <div className='address-item'>
                      <EmailIcon />
                      <span>{t('sigmaEmail')}</span>
                    </div>
                  </div>
                </div>
                <div className='map-view'>
                  <img src={LocationMapImg} alt='location-map' />
                </div>
              </div>
            </div>
          </div>
        </S.ContactsSection>
      </S.MainContent>
      <S.EllipseBackground>
        <div className='content'>
          <div className='big-ellipse'/>
        </div>
      </S.EllipseBackground>
    </React.Fragment>
  )
}

export default Contacts
