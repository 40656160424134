import * as types from '../actionTypes'

const initialState = {
  pageIndex: 1,
  category: null,
  subCategory: null,
  categories: [],
  topArticles: [],
  posts: [],
  totalCount: 0,
  perPageCount: 5,
  blogDetail: {}
}

const blogReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.SET_BLOG_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case types.SET_TOP_ARTICLES:
      return {
        ...state,
        topArticles: action.payload
      };
    case types.SET_BLOGS:
      return {
        ...state,
        ...action.payload
      };
    case types.SET_BLOG_DETAIL:
      return {
        ...state,
        blogDetail: {...action.payload}
      };
    default:
      return state;
  }
}

export default blogReducer
