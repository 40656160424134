import styled from 'styled-components'
import { Link } from 'react-router-dom'

const FooterSection = styled.section`
  width: 100%;
  background: ${props => props.theme.colors.blue800};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 75px 70px;
  color: ${props => props.theme.colors.white};

  .block {
    margin-right: 37px;

    &:first-child {
      min-width: 180px;
    }
  }

  .logo > img {
    width: 120px;
  }

  ${props => props.theme.breakpoints.down("lg")} {
    padding: 70px 0;
  }

  ${props => props.theme.breakpoints.down('xs')} {
    padding: 120px 0 50px;

    .block {
      display: none;
    }
  }

  ${props => props.theme.breakpoints.down(450)} {
    align-items: flex-start;

    > div {
      width: 100%;
    }
  }
`

const MenuTitle = styled(Link)`
  color: ${props => props.theme.colors.white};
  font: 14px/19px CircularStd-Medium;
  margin-bottom: 20px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.yellow};
  }

  ${props => props.theme.breakpoints.down(450)} {
    font: 20px/27px CircularStd-Medium;
  }
`

const MenuItem = styled(Link)<{transform?: string}>`
  color: white;
  font: 14px/18px CircularStd-Medium;
  margin-bottom: 10px;
  text-transform: capitalize;
  cursor: pointer;

  ${props => props.transform === 'initial' &&`
    text-transform: initial;
  `}

  &:hover {
    color: ${props => props.theme.colors.yellow};
  }

  ${props => props.theme.breakpoints.down(450)} {
    font: 16px/20px CircularStd-Medium;
  }
`

const FooterText = styled.p`
  margin: 0;
  color: white;
  font: 12px/20px Roboto-Regular;
  width: 100%;

  ${props => props.theme.breakpoints.down("lg")} {
    margin: 0 0 0 30px;
  }

  ${props => props.theme.breakpoints.down(450)} {
    margin: 30px 0 0 0 !important;
    text-align: center;
  }
`

const MenuList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 20px;

  .menu-group {
    display: flex;
    flex-direction: column;
  }

  ${props => props.theme.breakpoints.down("md")} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 30px;
  }

  ${props => props.theme.breakpoints.down(450)} {
    grid-template-columns: 1fr !important;
    grid-row-gap: 30px;
    width: 100%;

    .menu-group {
      text-align: center;
      width: 100%;
    }
  }

  ${props => props.theme.breakpoints.down("sm")} {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 30px;
  }
`

export { FooterSection, Content, MenuTitle, MenuItem, FooterText, MenuList }
