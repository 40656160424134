import React from 'react'
import { useTranslation } from 'react-i18next'
import { Hidden } from '@material-ui/core'
import { MenuOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

import * as S from './styles'
import { Logo } from '../Common/Icon'
import MenuItem from './MenuItem'
import SideMenu from '../SideMenu'

interface HeaderProps {
  hideHeaderBg: boolean,
  reachStart: boolean
}

const menu = [
  {
    name: 'whatWeDo',
    to: '/development',
    subMenu: true
  },
  {
    name: 'portfolio',
    to: '/portfolio',
    subMenu: true
  },
  {
    name: 'ourExpertise',
    to: '/expertise'
  },
  {
    name: 'company',
    to: '/company'
  },
  {
    name: 'blog',
    to: '/blog'
  },
  {
    name: 'contacts',
    to: '/contact-us'
  }
]

const Header = (props: HeaderProps) => {
  const { t } = useTranslation('menu')
  const [ open, setOpen ] = React.useState(false)

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setOpen(open)
  }

  return (
    <S.HeaderSection {...props}>
      <div className='container'>
        <Hidden smDown>
          <S.Content>
            <div className='menu-wrapper left'>
              {
                menu.slice(0, 3).map((item) => (
                  <MenuItem to={item.to} {...item} key={item.name}>
                    {t(`${item.name}.title`)}
                    <span className='indicator' />
                  </MenuItem>
                ))
              }
            </div>
            <Link to='/'>
              <Logo width={200} />
            </Link>
            <div className='menu-wrapper right'>
              {
                menu.slice(3, 6).map((item) => (
                  <MenuItem to={item.to} {...item} key={item.name}>
                    {t(`${item.name}.title`)}
                    <span className='indicator' />
                  </MenuItem>
                ))
              }
            </div>
          </S.Content>
        </Hidden>
        <Hidden mdUp>
          <S.MobileContainer>
            <Link to='/'>
              <Logo width={200} />
            </Link>
            <S.MenuButton onClick={toggleDrawer(true)}>
              <MenuOutlined />
            </S.MenuButton>
          </S.MobileContainer>
          <SideMenu
            open={open}
            closeMenu={toggleDrawer(false)}
          />
        </Hidden>
      </div>
    </S.HeaderSection>
  )
}

export default Header
