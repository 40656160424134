import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Spin} from 'antd'
import {Fade} from '@material-ui/core'
import {useLocation} from 'react-router'

import WhatWeDoSection from '../../components/WhatWeDoSection'
import ContactUsSection from '../../components/ContactUs'

import * as S from './styles'
import {SectionTitle} from '../../components/Common/CommonStyled'

import {ExpertsData, SkillsData} from './data'
import {DevelopmentType} from '../../shared/enums'
import {IExpert, ISkill} from '../../shared/interfaces'
import ExpertCard from '../../components/ExpertCard'

const OurExpertise = () => {
  const { t } = useTranslation('expertise')
  const location = useLocation()
  const [activeTab, setActiveTab] = useState<DevelopmentType>(DevelopmentType.WEB)
  const [skills, setSkills] = useState<ISkill[]>([])
  const [experts, setExports] = useState<IExpert[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const pathname = location.pathname.split('/')
    const category = pathname.length > 2 ? pathname.pop() : 'web'
    setActiveTab(category as DevelopmentType)
    setLoading(true)
    setTimeout(() => {
      const skills = SkillsData.filter((skill) => skill.category.indexOf(category as DevelopmentType) > -1)
      setSkills(skills)
      const experts = ExpertsData.filter((expert) => expert.category.indexOf(category as DevelopmentType) > -1)
      setExports(experts)
      setLoading(false)
    }, 500)

  }, [location])
  return (
    <React.Fragment>
      <S.WhatWeDoSection>
        <div className='container'>
          <div className='content'>
            <SectionTitle>{t('title')}</SectionTitle>
            <WhatWeDoSection pageName='expertise' activeTab={activeTab} />
          </div>
        </div>
      </S.WhatWeDoSection>
      <S.SkillsSection>
        <div className='container'>
          <div className='content'>
            <h4>{t(`skills`)}</h4>
            <div className='skill-list-container'>
              {
                loading &&
                <div className='loading-container'>
                  <Spin
                    spinning={ true }
                    size='large'
                  />
                </div>
              }
              <Fade in={!loading} timeout={{enter: 500, exit: 0}}>
                <div className='skill-list'>
                  {
                    skills.map((skill) => (
                      <div className='skill-list-item' key={skill.name}>
                        { skill.icon }
                        <span>{ skill.name }</span>
                      </div>
                    ))
                  }
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </S.SkillsSection>
      <S.OurExpertsSection>
        <div className='container'>
          <div className='content'>
            <SectionTitle>{ t(`experts.title`) }</SectionTitle>
            {
              loading &&
              <div className='loading-container'>
                <Spin
                  spinning={ true }
                  size='large'
                />
              </div>
            }
            <Fade in={!loading} timeout={{enter: 500, exit: 0}}>
              <div className='expert-card-list'>
                {
                  experts.map((expert) => (
                    <ExpertCard key={expert.name} {...expert} />
                  ))
                }
              </div>
            </Fade>
          </div>
        </div>
      </S.OurExpertsSection>
      <ContactUsSection />
      <S.EllipseBackground>
        <div className='content'>
          <div className='big-ellipse ellipse-1'/>
          <div className='big-ellipse ellipse-2'/>
          <div className='small-ellipse ellipse-3'/>
        </div>
      </S.EllipseBackground>
    </React.Fragment>
  )
}

export default OurExpertise
