import styled from 'styled-components'
import { Button } from 'antd'
import OwlCarousel from 'react-owl-carousel3';

const Carousel = styled(OwlCarousel)`
  .owl-dots {
    margin-top: 40px !important;
  }

  .owl-dot {
    width: 27px;
    height: 27px;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;

    span {
      background: ${props => props.theme.colors.light300};
      margin: 0 !important;
    }

    &.active {
      border-radius: 50%;
      border: 1px solid ${props => props.theme.colors.yellow};

      span {
        background: ${props => props.theme.colors.yellow} !important;
      }
    }
  }
`

const Container = styled.div<{dotsEnabled?: boolean}>`
  position: relative;
  
  ${props => props.dotsEnabled && `
    .prev-button, .next-button {
      top: calc(50% - 46px) !important;
    }
  `}

`

const IconButton = styled(Button)`
  width: 26px !important;
  height: 26px !important;
  border-radius: 50% !important;
  border: solid 0.5px ${props => props.theme.colors.blue50} !important;
  background: rgba(255,255,255,0.2) !important;
  display: flex !important;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${props => props.theme.colors.white};
    width: 13px;
    height: 13px;
    margin-top: 3px;
  }

  &.prev-button {
    position: absolute;
    left: -50px;
    top: calc(50% - 13px);
  }

  &.next-button {
    position: absolute;
    right: -50px;
    top: calc(50% - 13px);
  }

  ${props => props.theme.breakpoints.down("md")} {
    &.prev-button {
      left: -20px;
    }

    &.next-button {
      right: -20px;
    }
  }
`

export { Carousel, Container, IconButton }
