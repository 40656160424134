import React, { useState } from 'react'
import { Hidden } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import * as S from './styles'

interface DevelopmentDetailProps {
  data: Array<{
    title: string,
    description: string
  }>
}

const DevelopmentDetail = (props: DevelopmentDetailProps) => {
  const { data } = props;
  const [ selectedItem, setSelectedItem] = useState(data[0])
  const { t } = useTranslation('whatWeDo')

  return (
    <>
      <Hidden smDown>
        <S.Container>
          <S.TabList>
            {
              data.map((item) => (
                <S.TabItem active={selectedItem.title === item.title} onClick={() => setSelectedItem(item)} key={item.title}>
                  <div className='list-icon'><span /></div>
                  <h3>{t(`${item.title}`)}</h3>
                </S.TabItem>
              ))
            }
          </S.TabList>
          <S.DescriptionPanel>
            <h3 className='title'>{t(`${selectedItem.title}`)}</h3>
            <p className='description'>{t(`${selectedItem.description}`)}</p>
          </S.DescriptionPanel>
        </S.Container>
      </Hidden>
      <Hidden mdUp>
        <S.MobileContainer>
          {
            data.map((item) => (
              <S.DescriptionPanel scroll={'hide'} key={item.title}>
                <h3 className='title'>{t(`${item.title}`)}</h3>
                <p className='description'>{t(`${item.description}`)}</p>
              </S.DescriptionPanel>
            ))
          }
        </S.MobileContainer>
      </Hidden>
    </>
  )
}

export default DevelopmentDetail
