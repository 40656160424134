import React from 'react'
import { useTranslation } from 'react-i18next'

import ContactUsSection from '../../components/ContactUs'
import FeatureCarousel from '../../components/FeatureCarousel'
import WhatWeDoSection from '../../components/WhatWeDoSection'
import LandingCarousel from '../../components/LandingCarousel'
import TestimonialsCarousel from '../../components/TestimonialsCarousel'
import * as Icon from '../../components/Common/Icon'
import { Button } from '../../components/Common/Button'
import { SectionTitle } from '../../components/Common/CommonStyled'

import * as S from './styles'
import { feedbacks, featureItems } from './data'

const MainPage = () => {
  const { t } = useTranslation('main')

  return (
    <React.Fragment>
      <S.MainSection>
        <div className='container'>
          <div className='content'>
            <S.TopPanel>
              <div className='text-wrapper'>
                <h2>{t('main.subTitle')}</h2>
                <div className='typewriter'>
                  <div className='typewriter-text text-1'>
                    {t('main.title1')}
                  </div>
                  <div className='typewriter-text text-2'>
                    {t('main.title2')}
                  </div>
                </div>
                <p>{t('main.description')}</p>
              </div>
              <div className='social-links'>
                <span className='item'>{t('main.followUs')}</span>
                <span className='item'><Icon.TwitterWhiteIcon /></span>
                <span className='item'><Icon.InstagramWhiteIcon /></span>
              </div>
            </S.TopPanel>
            <S.ExodusLanding>
              <LandingCarousel>
                <div className='item'>
                  <div className='icon'>
                    <Icon.SasIcon />
                  </div>
                  <h3>{t('main.slider.whyUs.title')}</h3>
                  <div className='indicator' />
                  <p>{t('main.slider.whyUs.description')}</p>
                </div>
                <div className='item'>
                  <div className='icon'>
                    <Icon.WorldIcon />
                  </div>
                  <h3>{t('main.slider.ourMantra.title')}</h3>
                  <div className='indicator' />
                  <p>{t('main.slider.ourMantra.description')}</p>
                </div>
                <div className='item'>
                  <div className='icon'>
                    <Icon.SasIcon />
                  </div>
                  <h3>{t('main.slider.expectFromUs.title')}</h3>
                  <div className='indicator' />
                  <p>{t('main.slider.expectFromUs.description')}</p>
                </div>
                <div className='item'>
                  <div className='icon'>
                    <Icon.WorldIcon />
                  </div>
                  <h3>{t('main.slider.eccentric.title')}</h3>
                  <div className='indicator' />
                  <p>{t('main.slider.eccentric.description')}</p>
                </div>
              </LandingCarousel>
            </S.ExodusLanding>
            <div className='button-group'>
              <div className='button'>
                <S.IconButton>
                  <Icon.ConnectIcon />
                </S.IconButton>
                <span className='label'>{t('main.connectWithNewPeople')}</span>
              </div>
              <div className='button'>
                <S.IconButton>
                  <Icon.StorageIcon />
                </S.IconButton>
                <span className='label'>{t('main.unlimitedStorage')}</span>
              </div>
              <div className='button'>
                <S.IconButton>
                  <Icon.ChartGrowthIcon />
                </S.IconButton>
                <span className='label'>{t('main.increaseChanceToEngage')}</span>
              </div>
            </div>
          </div>
        </div>
      </S.MainSection>
      <S.WhatWeDoSection>
        <div className='container'>
          <div className='content'>
            <SectionTitle>{t('whatWeDo.title')}</SectionTitle>
            <WhatWeDoSection />
          </div>
        </div>
      </S.WhatWeDoSection>
      <S.BenefitsSection>
        <div className='container'>
          <div className='content'>
            <SectionTitle>{t('ourBenefits.title')}</SectionTitle>
            <div className='benefits-item-list'>
              <S.BenefitsItem>
                <div className='icon'>
                  <Icon.RocketIcon />
                </div>
                <div className='right'>
                  <h3>{t('ourBenefits.business.title')}</h3>
                  <p>{t('ourBenefits.business.description')}</p>
                </div>
              </S.BenefitsItem>
              <S.BenefitsItem>
                <div className='icon'>
                  <Icon.ShieldIcon />
                </div>
                <div className='right'>
                  <h3>{t('ourBenefits.security.title')}</h3>
                  <p>{t('ourBenefits.security.description')}</p>
                </div>
              </S.BenefitsItem>
              <S.BenefitsItem>
                <div className='icon'>
                  <Icon.ClipboardIcon />
                </div>
                <div className='right'>
                  <h3>{t('ourBenefits.compliance.title')}</h3>
                  <p>{t('ourBenefits.compliance.description')}</p>
                </div>
              </S.BenefitsItem>
              <S.BenefitsItem>
                <div className='icon'>
                  <Icon.CalculatorIcon />
                </div>
                <div className='right'>
                  <h3>{t('ourBenefits.effort.title')}</h3>
                  <p>{t('ourBenefits.effort.description')}</p>
                </div>
              </S.BenefitsItem>
            </div>
          </div>
        </div>
      </S.BenefitsSection>
      <S.FeaturedSection>
        <div className='container'>
          <div className='content'>
            <div className='left'>
              <SectionTitle>{t('featured.title')}</SectionTitle>
              <p className='description'>{t('featured.description')}</p>
              <Button>{t('featured.more')}</Button>
            </div>
            <FeatureCarousel data={featureItems} translationNs={'main'}/>
          </div>
        </div>
      </S.FeaturedSection>
      <S.TestimonialsSection>
        <div className='container'>
          <div className='content'>
            <SectionTitle>{t('testimonials.title')}</SectionTitle>
            <TestimonialsCarousel>
              {
                feedbacks.map((item, index) => (
                  <S.FeedbackItem key={index}>
                    <div className='item-container'>
                      <div className='header'>
                        <div className='avatar'>
                          <img src={ item.avatar } alt='user' />
                        </div>
                        <div className='profile'>
                          <p className='name'>{ item.name }</p>
                          <p className='address'>{ item.address }</p>
                        </div>
                      </div>
                      <p className='feedback'>{ item.feedback }</p>
                    </div>
                  </S.FeedbackItem>
                ))
              }
            </TestimonialsCarousel>
          </div>
        </div>
      </S.TestimonialsSection>
      <ContactUsSection />
    </React.Fragment>
  )
}

export default MainPage
